// Generated by grunt-webfont
// Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css



$cb-iconfont-font-path : "/assets/fonts/" !default;

@font-face {
	font-family:"cb-iconfont";
	src:url($cb-iconfont-font-path + "cb-iconfont.woff2?ad91bb80d51019d2ba9cce393e0b30f2") format("woff2"),
		url($cb-iconfont-font-path + "cb-iconfont.woff?ad91bb80d51019d2ba9cce393e0b30f2") format("woff"),
		url($cb-iconfont-font-path + "cb-iconfont.ttf?ad91bb80d51019d2ba9cce393e0b30f2") format("truetype");
	font-weight:normal;
	font-style:normal;
}


// Bootstrap Overrides
[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"cb-iconfont";
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}



// Icons


.icon-a-venir:before {
	content:"\f101";
}



.icon-abonnes:before {
	content:"\f102";
}



.icon-accueil-light:before {
	content:"\f1a2";
}



.icon-accueil:before {
	content:"\f1a1";
}



.icon-add-inscription:before {
	content:"\f103";
}



.icon-add:before {
	content:"\f104";
}



.icon-agenda:before {
	content:"\f105";
}



.icon-annee-2020:before {
	content:"\f106";
}



.icon-annee-2021:before {
	content:"\f107";
}



.icon-annee-2022:before {
	content:"\f108";
}



.icon-annee-2023:before {
	content:"\f109";
}



.icon-annee-2024:before {
	content:"\f10a";
}



.icon-annee-2025:before {
	content:"\f10b";
}



.icon-annee-2026:before {
	content:"\f10c";
}



.icon-annee-2027:before {
	content:"\f10d";
}



.icon-annee-2028:before {
	content:"\f10e";
}



.icon-annee-2029:before {
	content:"\f10f";
}



.icon-annee-2030:before {
	content:"\f110";
}



.icon-annexe-add:before {
	content:"\f111";
}



.icon-annexe:before {
	content:"\f112";
}



.icon-annuaire:before {
	content:"\f113";
}



.icon-archives-save:before {
	content:"\f114";
}



.icon-archives:before {
	content:"\f115";
}



.icon-arrow-down-full:before {
	content:"\f116";
}



.icon-arrow-left:before {
	content:"\f117";
}



.icon-arrow-right-full:before {
	content:"\f118";
}



.icon-arrow-small-down:before {
	content:"\f119";
}



.icon-assurance-maladie:before {
	content:"\f11a";
}



.icon-autres-contacts:before {
	content:"\f11b";
}



.icon-building:before {
	content:"\f11c";
}



.icon-bullet:before {
	content:"\f11d";
}



.icon-burger:before {
	content:"\f11e";
}



.icon-calculette:before {
	content:"\f11f";
}



.icon-calendar-small:before {
	content:"\f120";
}



.icon-calendar:before {
	content:"\f121";
}



.icon-camembert:before {
	content:"\f122";
}



.icon-certification-add:before {
	content:"\f123";
}



.icon-certifications:before {
	content:"\f124";
}



.icon-check:before {
	content:"\f125";
}



.icon-chiffre:before {
	content:"\f126";
}



.icon-classements:before {
	content:"\f127";
}



.icon-close:before {
	content:"\f128";
}



.icon-cnaf:before {
	content:"\f129";
}



.icon-cog-add:before {
	content:"\f12a";
}



.icon-cog:before {
	content:"\f12b";
}



.icon-collaborateurs:before {
	content:"\f12c";
}



.icon-commentaires:before {
	content:"\f12d";
}



.icon-complements:before {
	content:"\f12e";
}



.icon-comptabilite:before {
	content:"\f12f";
}



.icon-coordonnees-adresses:before {
	content:"\f130";
}



.icon-coordonnees:before {
	content:"\f131";
}



.icon-correspondances-add:before {
	content:"\f132";
}



.icon-correspondances:before {
	content:"\f133";
}



.icon-cotisation-liste-notaire:before {
	content:"\f134";
}



.icon-cotisation-liste-office:before {
	content:"\f135";
}



.icon-cotisation-office:before {
	content:"\f136";
}



.icon-cotisation-saisie-notaire:before {
	content:"\f137";
}



.icon-cotisation-saisie-office:before {
	content:"\f138";
}



.icon-cotisations:before {
	content:"\f139";
}



.icon-courrier:before {
	content:"\f13a";
}



.icon-courriers-add:before {
	content:"\f13b";
}



.icon-courriers:before {
	content:"\f13c";
}



.icon-croix-deces:before {
	content:"\f13d";
}



.icon-ctrcs-add:before {
	content:"\f1b5";
}



.icon-ctrcs:before {
	content:"\f1b6";
}



.icon-deces:before {
	content:"\f13e";
}



.icon-demandeur:before {
	content:"\f13f";
}



.icon-description:before {
	content:"\f140";
}



.icon-differends-add:before {
	content:"\f141";
}



.icon-differends:before {
	content:"\f142";
}



.icon-documents:before {
	content:"\f143";
}



.icon-download:before {
	content:"\f144";
}



.icon-edit:before {
	content:"\f145";
}



.icon-effectif:before {
	content:"\f146";
}



.icon-email-confirmation:before {
	content:"\f147";
}



.icon-email-document:before {
	content:"\f148";
}



.icon-email-questionnaire:before {
	content:"\f149";
}



.icon-emargement:before {
	content:"\f14a";
}



.icon-export-2022:before {
	content:"\f1a3";
}



.icon-export-2023:before {
	content:"\f1a4";
}



.icon-export-2024:before {
	content:"\f1a5";
}



.icon-export-2025:before {
	content:"\f1a6";
}



.icon-export-2026:before {
	content:"\f1a7";
}



.icon-export-2027:before {
	content:"\f1a8";
}



.icon-export-2028:before {
	content:"\f1a9";
}



.icon-export-2029:before {
	content:"\f1aa";
}



.icon-export-2030:before {
	content:"\f1ab";
}



.icon-export:before {
	content:"\f14b";
}



.icon-fermer:before {
	content:"\f14c";
}



.icon-fiche:before {
	content:"\f14d";
}



.icon-fil-infos-add:before {
	content:"\f14e";
}



.icon-fil-infos:before {
	content:"\f14f";
}



.icon-filter:before {
	content:"\f150";
}



.icon-filtre:before {
	content:"\f151";
}



.icon-formation-add:before {
	content:"\f152";
}



.icon-formations:before {
	content:"\f153";
}



.icon-frais-traitement:before {
	content:"\f154";
}



.icon-groupes-add:before {
	content:"\f155";
}



.icon-groupes:before {
	content:"\f156";
}



.icon-habilitations:before {
	content:"\f157";
}



.icon-historique:before {
	content:"\f158";
}



.icon-identite:before {
	content:"\f159";
}



.icon-images:before {
	content:"\f15a";
}



.icon-import:before {
	content:"\f15b";
}



.icon-informations:before {
	content:"\f15c";
}



.icon-inscrits:before {
	content:"\f15d";
}



.icon-inspecteurs:before {
	content:"\f15e";
}



.icon-inspection-export-inspecteur:before {
	content:"\f15f";
}



.icon-inspections:before {
	content:"\f160";
}



.icon-labels-add:before {
	content:"\f161";
}



.icon-labels:before {
	content:"\f162";
}



.icon-lettre-type-add:before {
	content:"\f163";
}



.icon-lettre-type:before {
	content:"\f164";
}



.icon-liste-attente:before {
	content:"\f165";
}



.icon-lock-2022:before {
	content:"\f1ac";
}



.icon-lock-2023:before {
	content:"\f1ad";
}



.icon-lock-2024:before {
	content:"\f1ae";
}



.icon-lock-2025:before {
	content:"\f1af";
}



.icon-lock-2026:before {
	content:"\f1b0";
}



.icon-lock-2027:before {
	content:"\f1b1";
}



.icon-lock-2028:before {
	content:"\f1b2";
}



.icon-lock-2029:before {
	content:"\f1b3";
}



.icon-lock-2030:before {
	content:"\f1b4";
}



.icon-logiciels:before {
	content:"\f166";
}



.icon-map:before {
	content:"\f167";
}



.icon-megaphone:before {
	content:"\f168";
}



.icon-menu:before {
	content:"\f169";
}



.icon-modifier:before {
	content:"\f16a";
}



.icon-mosaique:before {
	content:"\f16b";
}



.icon-negociateurs:before {
	content:"\f16c";
}



.icon-nombre-actes:before {
	content:"\f16d";
}



.icon-nombre-salaries:before {
	content:"\f16e";
}



.icon-nombre-successions:before {
	content:"\f16f";
}



.icon-notaire:before {
	content:"\f170";
}



.icon-notaires-add:before {
	content:"\f171";
}



.icon-notaires:before {
	content:"\f172";
}



.icon-office-add:before {
	content:"\f173";
}



.icon-office:before {
	content:"\f174";
}



.icon-organisme-add:before {
	content:"\f175";
}



.icon-organismes:before {
	content:"\f176";
}



.icon-paper-plane:before {
	content:"\f177";
}



.icon-penalites:before {
	content:"\f178";
}



.icon-personne-exterieure-add:before {
	content:"\f179";
}



.icon-personne-exterieure:before {
	content:"\f17a";
}



.icon-plaignant:before {
	content:"\f17b";
}



.icon-precisions:before {
	content:"\f17c";
}



.icon-predecesseur:before {
	content:"\f17d";
}



.icon-print:before {
	content:"\f17e";
}



.icon-recapitulatif:before {
	content:"\f17f";
}



.icon-recherche:before {
	content:"\f180";
}



.icon-reclamations-add:before {
	content:"\f181";
}



.icon-reclamations:before {
	content:"\f182";
}



.icon-recus-livraison:before {
	content:"\f183";
}



.icon-remove-alternative:before {
	content:"\f184";
}



.icon-remove:before {
	content:"\f185";
}



.icon-repertoire:before {
	content:"\f186";
}



.icon-reponse:before {
	content:"\f187";
}



.icon-sablier:before {
	content:"\f188";
}



.icon-saisie-avis-motive:before {
	content:"\f189";
}



.icon-sauvegarde:before {
	content:"\f18a";
}



.icon-search:before {
	content:"\f18b";
}



.icon-signature-add:before {
	content:"\f18c";
}



.icon-signature:before {
	content:"\f18d";
}



.icon-sinistres-add:before {
	content:"\f18e";
}



.icon-sinistres:before {
	content:"\f18f";
}



.icon-star:before {
	content:"\f190";
}



.icon-statistiques:before {
	content:"\f191";
}



.icon-successions-add:before {
	content:"\f192";
}



.icon-successions:before {
	content:"\f193";
}



.icon-suivi:before {
	content:"\f194";
}



.icon-tableau-bord:before {
	content:"\f195";
}



.icon-termine:before {
	content:"\f196";
}



.icon-tests-add:before {
	content:"\f197";
}



.icon-tests:before {
	content:"\f198";
}



.icon-time:before {
	content:"\f199";
}



.icon-univers-annuaire:before {
	content:"\f19a";
}



.icon-univers-formations:before {
	content:"\f19b";
}



.icon-unlock:before {
	content:"\f19c";
}



.icon-urssaf:before {
	content:"\f19d";
}



.icon-usb:before {
	content:"\f19e";
}



.icon-vefa:before {
	content:"\f19f";
}



.icon-voir:before {
	content:"\f1a0";
}

