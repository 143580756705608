.confirmation-overlay{
    position:fixed;
    width: 100vw;
    height:100vh;

    top:0;
    left:0;

    background-color: rgba($c_primary, .8);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 99;

    >.content{
        flex: 1 1 600px;
        max-width: 600px;

        //Children
        >.text{
            margin: 0;

            font-family: $f_main;
            font-weight: bold;
            @include rem('font-size', 20px);
            @include rem('line-height', 24px);
            color: white;
            text-align: center;
        }
        >.actions{
            margin: 0;
            margin-top: 20px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

        }
    }
}
