.breadcrumbs-block{
	display: flex;
	align-items: center;
    margin-bottom: 10px;

	color: $c_text;
	@include rem(font-size, 11px);

	// Children

	> .sep{
		margin: 0 10px;
	}

	> .link{
		font-weight: bold;

		transition: color ease .3s;

		&:hover{
			color: $c_primary;
		}
	}
}
