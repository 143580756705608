.state-bullet {
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 50%;

  &.-actif,
  &.-realisee,
  &.-reception,
  &.-oui {
    color: $c_actif;
  }

  &.-empty {
    color: white;
  }

  &.-rappel {
    color: $c_rappel;
  }

  &.-report,
  &.-a-faire,
  &.-convocation {
    color: $c_report;
  }

  &.-annule,
  &.-absent,
  &.-bureau,
  &.-non {
    color: $c_annule;
  }

  &.-before-element {
    margin: 0;
    margin-right: 10px;

    &::before {
      display: block;
    }
  }

  &::before {
    display: inline-block;
    @include rem(font-size, 13px);
    @include rem(line-height, 13px);
  }

  &.-next-step {
    line-height: 35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    color: #ffffff;

    &:before {
      display: none;
    }

    &.-empty {
      color: #000000;
      background-color: white;
    }

    &.-color-actif{
      background-color: $c_actif;
    }
    &.-color-rappel{
      background-color:$c_rappel;
    }
    &.-color-report{
      background-color:$c_report;
    }
    &.-color-annule{
      background-color:#FC414F;
    }
    &.-color-default{
      background-color:$c_text;
    }
    &.-color-actif-border-report {
      background-color: $c_actif;
      border: 2px solid $c_report;
    }
  }
}
