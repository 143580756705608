.leaflet-container {
	width: 100%;
	height: 100%;
}

.map-wrapper{
	position: relative;
	margin: 20px 0 0;
	height: calc(100vh - 267px);
	z-index: 1;
}

.map-marker-cluster{
	width: 30px;
	height: 30px;
	display: flex!important;
	align-items: center;
	justify-content: center;
	@include rem(font-size, 12px);
	font-weight: bold;

	background-color: rgba($c_secondary,.5);
	border-radius: 100%;
	border: 6px solid $c_secondary;
}

.leaflet-popup-content{
    >.link{
        text-decoration: none;

        //Children
        >.title{
            margin: 0;
            margin-bottom: 10px;
            @include rem(font-size, 14px);
            font-weight: bold;
        }
        >.subtitle{
            margin: 0;
            margin-bottom: 5px;
            @include rem(font-size, 12px);
            font-weight: bold;
        }
        >.text{
            margin: 0;

            @include rem(font-size, 12px);
            font-weight: normal;
        }

        >.title,
        >.subtitle,
        >.text{
            color:$c_primary;
            transition: color .3s ease;

            //Variant
            &:hover{
                color:$c_secondary;
            }
        }

        >.list{
            color:$c_primary;
            @include rem(font-size, 12px);
            font-weight: normal;

            //Children
            >.title{
                margin-bottom: 0;
                font-weight: bold;
            }
        }
    }
}
