.g-content{
    position: relative;
    padding: 30px 40px;

    &.-no-padding-mobile {
        @include mq(w600){
            padding: 0;
        }
    }
}
