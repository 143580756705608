.years-list-wrapper{
    padding:20px;
    margin: 0;
    margin-bottom: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    background-color: $c_background_light;

    >.year{
        margin-right: 40px;

        font-family: $f_main;
        font-weight: bold;
        @include rem('font-size', 50px);
        @include rem('line-height', 50px);
        color:$c_primary;
        opacity: .2;

        transition: opacity .3s ease;
        cursor: pointer;

        //Variant
        &:last-child{
            margin-right: 0;
        }
        &:hover{
            opacity: .6;
        }
        &.-active{
            opacity: 1;
        }
    }
}
