.g-link{
    display: block;
    margin: 0;
    padding: 0;

    font-family: $f_main;
    @include rem('font-size', 12px);
    @include rem('line-height', 16px);
    font-weight: 500;

    background: none;
    border: none;
    outline: none;

    transition: color .3s ease;

    //Variant
    &.-primary{
        color:$c_primary;

        //Variant
        &.-active,
        &:hover{
            color:$c_secondary;
        }
    }
    &.-text{
        color:$c_text;

        //Variant
        &.-active,
        &:hover{
            color:$c_secondary;
        }
    }
    &.-white{
        color:white;

        //Variant
        &.-active,
        &:hover{
            color:$c_secondary;
        }
    }
    &.-margin-top{
        margin-top: 20px;
    }
    &.-right{
        text-align: right;
    }
    &.-center{
        text-align: center;
    }
    &.-on-column-list{
        margin-bottom: 5px;

        //Variant
        &.-last-child{
            margin-bottom: 0;
        }
    }
    &.-bold{
        font-weight: bold;
    }
    &.-underline-on-hover{
        display: inline-block;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}
.g-picto-link{
    @include rem('font-size', 22px);
    @include rem('line-height', 22px);
    transition: color .3s ease;
    text-decoration: none;
    cursor: pointer;

    //Variant
    &.-size-2{
        @include rem('font-size', 15px);
        @include rem('line-height', 15px);
    }
    &.-secondary{
        color: $c_secondary;

        //Variant
        &:hover{
            color:white;
        }
    }
}
