.accordion-wrapper{
    position: relative;

    //Children
    &.-accueil-notes{

        &.-type-form{
            &::after{
                display: none;
            }
        }

        //Children
        >.accordion-content{
            padding-top:20px;
            padding-left:20px;

            border-left:1px solid $c_separator_light;

            //Children
            >.accordion-header{
                flex-flow: row wrap;

                &.-active{
                    padding-bottom: 20px;
                    border-bottom:1px solid $c_separator_light;
                }
            }

            //Variant
            &:after{
                display: none;
            }
        }
    }
    &.-classement{
        z-index: 2;
        background-color:$c_background_light;

        > .accordion-content::after{
            display: none;
        }
    }
    &.-type-form{
        z-index: 2;
        background-color:$c_background_light;

        &:before{
            content: "";
            display:block;

            height:1px;
            width: 100%;

            position: absolute;
            top:-1px;
            left:0;

            background-color:white;
        }
        &:after{
            content: "";
            width: 140px;
            height: 100%;

            position: absolute;
            right:-140px;
            top:0;
            background-color:$c_background_light;

        }

        .accordion-content{
            &:after{
                display: none;
            }
        }
    }
    &.-no-padding-left-right{
        >.accordion-content{
            padding-left:0;
            padding-right: 0;
        }
    }
    &.-no-padding-top{
        >.accordion-content{
            padding-top:0;
        }
    }
    &.-no-padding-left{
        >.accordion-content {
            padding-left: 0;
        }
    }

    &:last-child{
        >.accordion-content{
            border-bottom:none;
        }
    }
}

.accordion-content{
    &:after{
        width: 100%;
        height: 1px;
        content: "";
        transform: translateY(30px);
        display: block;
        background-color:$c_separator_light;
    }
}

.accordion-header{
    margin: 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor:pointer;

    //Variant
    &.-active{
        margin-bottom: 20px;

        //Children
        >.left{
            //Children
            >.arrow{
                transform: rotate(180deg);
            }
        }
    }
    //Children
    >.left{
        flex: 1;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        //Children
        >.icon{
            margin-right: 10px;

            @include rem('font-size', 30px);
            @include rem('line-height', 30px);
            color:$c_secondary;
        }
        >.title{
            font-family: $f_second;
            @include rem('font-size', 20px);
            @include rem('line-height', 20px);
            color:$c_primary;
            font-weight: bold;
        }
        >.arrow{
            margin-left: 10px;
            @include rem('font-size', 8px);
            @include rem('line-height', 8px);
            color:$c_primary;
        }
    }
    >.right{
    }


    >.date-modification{
        flex: 1 1 100%;
        margin-top: 5px;

        @include rem('font-size', 11px);
        @include rem('line-height', 11px);
        color:$c_text;
    }
}
