*{
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(#000,.5);
}

html{
	font-size: 62.5%;
}

body{
    font-family: 'Montserrat', sans-serif  !important;
    color:$c_text_dark;
}

.app-wrapper{

}


$widthOpened : 240px;
$widthClosed : 60px;
.page-wrapper{

	//Variant

	&.-no-scroll{
		overflow: hidden;
	}

	&.-full-screen{
		padding: 80px 80px 20px;
        width: 100%;
        max-width: 100vw;
		min-height: 100vh;
		background-color: #fff;

        //Media
        @include mq(tabletfull){
            padding: 0;
        }

		//Variant

		&.-no-border{
			padding: 40px 40px 20px;

			// Children

			> .content{
				padding: 0;
			}
		}

		&.-login{
			padding: 40px 40px 20px;

			// Children

			> .content{
				min-height: calc(100vh - 120px);
				max-height: calc(100vh - 120px);
			}
		}
        &.-advanced-search{
            >.content{
                min-height: calc(100vh - 80px);
                max-height: calc(100vh - 80px);
                overflow: auto;
            }
        }


		// Children

		>.content{
            position: relative;
            padding:30px 40px;
			height: 100%;

            min-height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);

			width: 100%;

            display: flex;
            flex-flow: row nowrap;
			align-items: center;
			justify-content: center;

			overflow: auto;

            //Media
            @include mq(tabletfull){
                min-height: 100vh;
            }
		}
	}

    &.-menu-alternative{
        > .page-menu{
            background: $c_secondary;

            > .button {
                color: $c_primary;
            }
            > .content{
                > .search{
                    >.icon{
                        color: white;
                    }
                    &:hover{
                        color: $c_primary;
                    }
                }
                >.menu-link{

                    &:hover{
                        color: $c_primary;
                    }
                }
            }
        }
        .menu-module{
            &.-is-opened{
                >.name{
                    color:$c_primary;
                }
            }
            >.name{
                &:hover{
                    color:$c_primary;
                }
            }
            >.submodules{
                >.link{
                    &:hover{
                        color:$c_primary;
                    }
                    >.icon{
                        color:$c_primary;
                    }
                }
            }
        }
    }

	&.-with-menu{
		position: relative;
		display: flex;
		height: 100vh;
		padding-left: $widthOpened;

		transition: padding .3s ease;

		// Media

		@include mq(tabletfull){
			padding-left: $widthClosed;
		}

		// Variants

		&.-closed-menu{
			padding-left: $widthClosed;

			> .page-menu{
				width: $widthClosed;
                padding: 0 5px;

                >.button{
                    width: 100%;
                    height: 40px;
                }

				> .content, > .account-block{
					opacity: 0;
					visibility: hidden;

					transition: opacity 0s 0s ease, visibility 0s 0s ease;
				}
			}
		}
	}

	&.-bg-secondary{
		background-color: $c_secondary;
	}
	&.-bg-primary{
		background-color: $c_primary;
	}

	> .content{
		width: 100%;
        overflow: auto;
        min-height: 100vh;

		//Variant
		&.-bg-primary{
			background-color:$c_primary;
		}
		&.-bg-white{
			background-color:#fff;
		}
		&.-no-flex{
			display: block;
			width: 100%;
		}

        >.list{
            min-height: 100%;
        }
	}
}

.border-footer{
    border-bottom: 60px solid $c_primary;
}


.menu-link{
	@include rem(font-size,20px);
	font-family: $f_second;
	text-transform: uppercase;
	text-decoration: none;

	transition: color ease .3s;

	// Variants

	&:hover{
		color: $c_secondary;
	}
}

.page-menu{
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: $widthOpened;
	padding: 0 20px;

	background: $c_primary;

	z-index: 10;

	transition: width .3s ease;

	// Children

	> .button{
		color: $c_secondary;
		background: none;
		outline: none;
		border: 0;
		padding: 0;
        margin-top: 10px;

        //Variant
        &:first-child{
            margin-top: 30px;
        }

		// Children

		> .icon{
			@include rem(font-size, 20px);
			@include rem(line-height, 20px);

            &.icon-mosaique{
                @include rem(font-size, 13px);
                @include rem(line-height, 13px);
            }
			&.-small{
				@include rem(font-size, 10px);
			}
		}
	}

	> .content, > .account-block{
		opacity: 1;
		visibility: visible;

		transition: opacity .3s .3s ease, visibility .3s .3s ease;
	}

	> .content{
		height: calc(100% - 250px);
		overflow: auto;
		color: #fff;

		// Children

		> .search{
			@extend .menu-link;

			// Children

			> .icon{
				margin-right: 10px;
				color: $c_secondary;
				@include rem(font-size,17px);
				@include rem(line-height,20px);
			}
		}
	}
}

.menu-module{
	margin-top: 20px;

	// Variants

	&.-is-opened{
		> .name{
			color: $c_secondary;
		}
		> .submodules{
			margin-top: 20px;
			max-height: 500px;
			opacity: 1;
			visibility: visible;
		}
	}

	// Children

	> .name{
		@extend .menu-link;
		cursor: pointer;
	}

	> .submodules{
		opacity: 0;
		visibility: hidden;
		max-height: 0;
		overflow: hidden;
		padding-left: 15px;
		margin-top: 0;

		border-left: 2px solid $c_secondary;

		transition: opacity ease .5s, visibility ease .5s, margin ease .5s,max-height .5s ease;

		// Children

		> .link{
			position: relative;
			display: block;
			margin: 15px 0;
			@include rem(font-size, 13px);
			font-weight: 500;
			text-decoration: none;

			transition: padding ease .3s, color ease .3s;

			&:first-child{
				margin-top: 0;
			}

			&:last-child{
				margin-bottom: 0;
			}

			&:hover, &.-active{
				padding-left: 15px;
				color: $c_secondary;

				> .icon{
					opacity: 1;
					visibility: visible;
					transform:  translateY(-50%);
				}
			}

			// Children

			> .icon{
				position: absolute;
				top: 50%;
				left: 0;
				color: $c_secondary;
				@include rem(font-size, 11px);
				opacity: 0;
				visibility: hidden;
				transform: translateX(-100%) translateY(-50%);

				transition: all ease .3s;
			}
		}
	}
}

.prelative{
    position:relative;
}
.rnc__base{
    top: 0;
}

.d-flex {
	display: flex;
}
