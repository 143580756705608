$c_primary: #171F46;
$c_secondary: #DEB578;
$c_text: #444444;
$c_text_light: #666666;
$c_text_dark: #272727;

$c_separator_light: #CCCCCC;
$c_separator_med: #707070;
$c_separator_dark: #363636;

$c_background_light: #F5F5F5;
$c_background_overlay: #040A24;

$c_error: #F43C24;

$c_placeholder: #E3E3E3;

$c_text: #666666;

$c_actif: #C3E672;
$c_rappel: #F5F52A;
$c_annule: #FC414F;
$c_report: #F8AF41;



