.react-datepicker-popper {
    .react-datepicker {
        font-family: $f_main;
        @include rem('font-size', 11px);
        color:$c_text_dark;

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            width: 2.2rem;
            line-height: 2.2rem;
        }
        .react-datepicker__time-container--with-today-button{
            right: -84px;
            top: -1px;
        }

        .react-datepicker__header--time{
            padding-top:17px;
            padding-bottom:16px;
        }

        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
            min-height: 185px;
        }

        .react-datepicker-time__header{
            visibility: hidden;
            position: relative;

            &:after{
                content:"Horaires";
                display: block;
                visibility: visible;
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translateY(-50%) translateX(-50%);
            }
        }


        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
            background-color:$c_primary;
        }
    }
}
