$f_main: "Montserrat", sans-serif;
$f_second: "Bebas Neue", sans-serif;
@font-face {
    font-family: 'Bebas Neue';
    src: url('/assets/fonts/BebasNeueRegular.woff2') format('woff2'),
    url('/assets/fonts/BebasNeueRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('/assets/fonts/BebasNeueBold.woff2') format('woff2'),
    url('/assets/fonts/BebasNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('/assets/fonts/BebasNeueLight.woff2') format('woff2'),
    url('/assets/fonts/BebasNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Italic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

