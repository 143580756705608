@media print {
    html, body {
        overflow: visible! important;
        height: auto !important;
        max-height: auto !important;
    }
    iframe {
        overflow-x: visible! important;
    }
    div, main, article, section, table, p,
    h1, h2, h3, h4, h5, h6, header, footer {
        box-shadow: none !important;
    }

    .tabs-wrapper,
    .list-aside,
    .page-header .right,
    .accordion-header .right,
    .accordion-header .left .arrow,
    .breadcrumbs-block,
    .page-menu,
    .list-filters,
    .pagination-wrapper {
        display: none;
    }
    .border-footer{
        border:none;
    }

    .page-wrapper {
        display: block !important;
        padding: 0 !important;
        overflow: hidden !important;

    }

    .list-table {
        width: 100%;
        margin: 0;
    }

    .g-content{
        overflow: hidden !important;
    }

    .list-content {
        padding: 0 !important;
    }

    .list-table .td {
        font-size: 11px;
        line-height: 12px;
    }

    .page-header {
        padding-left: 0 !important;
    }

    .page-wrapper,
    .page-wrapper > .content {
        height: initial !important;//nécessaire pour que l'impression prenne en compte toute la courante
    }

    .informations-wrapper.-chiffres {
        display: block;
        break-inside: avoid;
        page-break-inside: avoid;
    }
    .informations-wrapper >.columns.-chiffre{
        justify-content: flex-start;
    }

    .informations-wrapper > .columns > .column.-chiffre {
        display: inline-block;
        max-width: 25%;
        margin: 0 5px;

        .informations-chiffre{
            display: block;
            break-inside: avoid;
            page-break-inside: avoid;
            >*{
                display: block;
                break-inside: avoid;
                page-break-inside: avoid;
            }
        }
    }

    .accordion-wrapper {
        width: 100%;
    }
    .accordion-wrapper > .accordion-content{
        width: 100%;
    }

    .accordion-wrapper:not(:first-child){
    }

    .informations-wrapper.-notaires {
        >.columns{
            display: block;
            overflow: hidden;
            width: 18cm;

            >.column{
                &.-notaire{
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 0;
                    margin-bottom: 10px;
                    margin-left: 5px;
                    margin-right: 5px;

                    width: 98px;
                    max-width: 98px;
                    padding-right: 0 !important;
                    break-inside: avoid;
                    page-break-inside: avoid;

                    >.image-wrapper{
                        display: block;

                        >.informations-image.-list-notaire{
                            display: block;

                            >.image{
                                margin-top: 1px;
                                break-inside: avoid;
                            }
                        }
                    }
                    >.content{
                        min-height: 85px;
                    }
                }
            }
            &:after{
                clear: both;
            }
        }
    }

    .informations-image.-resume{
        width: 182px;
        display: block;

        >.image{
            width: 182px;
            max-width: none;
            display: block;
        }
    }



    .informations-wrapper > .columns:after {
        content:"";
        display:block;
        clear:both;
    }

    .informations-image.-list-notaire {
        text-align: center;
    }

    .informations-image > .image {
        display: initial;
        max-width: 100px;
    }

    .informations-notaire > .content {
        min-height: auto;
    }

    .informations-wrapper > .columns > .column.-notaire,
    .informations-notaire > .content,
    .informations-block {
        page-break-after: avoid;
        break-inside: avoid;
    }

    .informations-header{
        page-break-inside: avoid;
        break-inside: avoid;

        >.icon,
        >.icon:after,
        >.title{
            page-break-inside: avoid;
            break-inside: avoid;
        }
        >.icon,
        >.title{
            display: inline-block;
            vertical-align: middle;
        }
    }

    .informations-wrapper {
        width: 18cm;
        clear: both;
    }
    .informations-wrapper .column {
        display: block;
        width:100%;
    }
    .informations-wrapper .columns >.column,
    .informations-wrapper .columns >.column.-auto{
        padding-top: 0;
        margin-top: 0;
        display: inline-block;
        width: 29%;
        max-width: 29%;

        margin-right: 0;
        padding-right:35px;
    }


    .informations-block{
        >.label, >.value, a{
            page-break-inside: avoid;
            break-inside: avoid;
        }

        >.value{
            display: block;
        }
        a{
            display: block;
            word-break: break-word;
            overflow: visible;
        }
    }

    .informations-image-wrapper{
        &.-labels{
            display: table;
            overflow: hidden;

            >.informations-image{
                display: table-cell;
                padding-right: 10px;
            }
        }
    }

    .informations-wrapper{
        &.-recus-wrapper,
        &.-break-inside-avoid{
            .columns{
                page-break-inside: avoid;
                break-inside: avoid;

                >.column{
                    page-break-inside: avoid;
                    break-inside: avoid;
                }
            }
        }
    }

    .statistiques-graphiques{
        display: block;

        >.graphique{
            display: block;
            margin:0;
            margin-bottom:40px;
            max-width: 100%;
            width:100%;
            page-break-after: always;

            &:last-child{
                page-break-after: auto;
            }

            >canvas{
                width:600px !important;
                height:300px !important;
            }
        }
    }

}
