.search-header{
	width: 100%;
	padding: 35px;

	background-color: $c_background_light;
}

.search-content{
	padding: 35px;

	// Children

	> .title{
		margin: 0 0 30px;
		color: $c_primary;
		@include rem(font-size, 20px);
	}
}

.search-result-header{
	display: flex;
	align-items: center;
	padding: 20px;

	color: $c_primary;
	background-color: $c_background_light;

	// Children

	> .icon{
		@include rem(font-size, 50px);
		width: 55px;
		text-align: center;

		&.icon-reclamations{
			@include rem(font-size, 40px);
		}
	}

	> .sep{
		display: block;
		margin: 0 20px;
		width: 1px;
		height: 25px;
		background-color: $c_secondary;
	}

	> .text{
		margin-top: 6px; // Compensation de la font pas calée
		font-family: $f_second;
		@include rem(font-size, 30px);
		font-weight: bold;
	}

	> .more{
		margin: 0 0 0 auto;
	}
}

.search-result-row{
	display: flex;
	padding: 20px 0;

	text-decoration: none;

	transition: background-color ease .3s,padding ease .3s;

	border-bottom: 1px solid $c_separator_light;

	// Variants

	&:hover{
		padding-left: 20px;
		background-color: rgba($c_primary,.1);
	}

	&:last-child{
		border-bottom: 0;
	}

	// Children

	> .illustration{
		position: relative;
		margin: 0 15px 0 0;
		padding: 0;
		width: 82px;
		height: 82px;

		// Children

		&::before{
			content: "";
			width: 90px;
			height: 90px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%,0);
			border: 2px solid $c_primary;
			border-radius : 100%;
		}

		> .picture{
			width: 82px;
			height: 82px;
			border-radius : 100%;

			background-position: center center;
			background-size: cover;
		}
	}

	> .content{
		color: $c_text;
		@include rem(font-size, 12px);
		@include rem(line-height, 16px);
		font-weight: 500;

		// Children

		> .title{
			margin: 0;
			color: $c_primary;
			@include rem(font-size, 14px);
		}

		> .subtitle{
			margin: 5px 0;
			color: $c_secondary;
			@include rem(font-size, 12px);
			font-weight: bold;
			font-style: italic;
		}

		> .text{
			margin: 0;
		}
	}
}

.search-empty-search{
	margin: 40px auto;
	max-width: 300px;
	color: $c_text;
	@include rem(font-size, 14px);
	text-align: center;
}