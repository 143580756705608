.mozaic-block{
	position: relative;
	height: 100%;
	overflow: auto;

	// Children

	> .modules{
		display: flex;
		flex-flow: row wrap;
		width: 100%;
        height: 100%;

        //Variant
        &.-sp1{
            height: 100%;
        }
	}
}

.mozaic-overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;

	background-color: #eeeeee;
	z-index: 1;
	transform: translateX(-101%);
	opacity: 0;
	visibility: hidden;
	transition: opacity .5s ease, visibility .5s ease, transform .5s ease;

	// Variants

	&.-show{
		opacity: 1;
		visibility: visible;
		transform: none;
		transition: transform .5s ease;
	}

	// Children

	> .left{
		position: relative;
		flex: 1 1 25%;
		max-width: 25%;
		padding: 30px;

		background-color: $c_secondary;

		// Children

		> .close{
			background-color: transparent;
			border: none;
		}

		> .content{
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			transform: translate3d(-50%,-50%,0);

			// Children


			> .icon{
				@include rem(font-size, 50px);

                //Media
                @include mfmq(desktop){
                    @include rem(font-size, 75px);
                }
			}

			> .title{
				font-family: $f_second;
				@include rem(font-size, 30px);

                //Media
                @include mfmq(desktop){
                    @include rem(font-size, 45px);
                }
			}
		}
	}

	> .right{
		display: flex;
		flex-flow: row wrap;
		flex: 1 1 75%;
		max-width: 75%;
		max-height: 100%;
		overflow: auto;
	}
}

.mozaic-module{
	flex: 1 1 20%;
	max-width: 20%;
	padding: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	text-align: center;
	text-decoration: none;

	background-color: #fff;
	border: 1px solid $c_separator_light;
	border-top: 0;
	border-left: 0;

	cursor: pointer;

	transition: background-color ease .6s;

	// Variant
    &.-sp1{
        height: 100%;
        flex: 1 1 100%;
        max-width: 100%;
        border:none;
    }
	&.-big{
		> .content > .icon{
			@include rem(font-size, 50px);

            //Media
            @include mfmq(desktop){
                @include rem(font-size, 75px);
            }
		}
	}

	&.-placeholder{
		pointer-events: none;

		> .content > .icon{
			@include rem(font-size, 95px);
			color: $c_placeholder;
		}
	}

	&.-is-2{
		flex: 1 1 50%;
		max-width: 50%;
		height: 100%;
	}

	&.-is-between-2-4{
		flex: 1 1 50%;
		max-width: 50%;
		height: 50%;
	}

	&.-is-4-more{
		flex: 1 1 33.33%;
		max-width: 33.33%;
	}
	
	&:hover{
		background-color: rgba($c_primary, .02);
	}

	// Children

	> .content {
		height: 100px;
		> .icon{
			color: $c_secondary;
			@include rem(font-size, 42px);

            @include mfmq(desktop){
                @include rem(font-size, 63px);
            }
		}

		> .title{
			margin: 20px 0 0;
			font-family: $f_second;
			@include rem(font-size, 22px);

            @include mfmq(desktop){
                @include rem(font-size, 33px);
            }
		}
	}
}
