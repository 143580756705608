.export-screen{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 20;
	transition: opacity ease .3s, visibility ease .3s;

	// Variants

	&.-hide{
		opacity: 0;
		visibility: hidden;
	}

	// Children

	.export-close{
		position: absolute;
		top: 30px;
		right: 30px;
		padding: 0;
		margin: 0;
		background: none;
		border: none;
		outline: none;
		@include rem(font-size, 20px);
		transition: transform ease .3s;

		// Variants

		&:hover{
			transform: rotate(90deg);
		}
	}

	.description-text {
		font-size: 1.2rem;
		margin-bottom: 30px;
		
		.-bold {
			font-weight: bold;
		}

		.title {
			font-family: "Bebas Neue", sans-serif;
			font-size: 20px;
			font-size: 2rem;
			line-height: 20px;
			line-height: 2rem;
			color: #171F46;
			font-weight: bold;
		}

		li {
			margin-bottom: 15px;
		}
	}

	.form-errors {
		.title {
		  font-family: "Bebas Neue", sans-serif;
		  font-size: 20px;
		  font-size: 2rem;
		  line-height: 20px;
		  line-height: 2rem;
		  color: #171F46;
		  font-weight: bold;
		}
		.error-line {
		  display: flex;
		  align-items: center;
		  gap: 15px;
		  font-size: 1.2rem;
		  .-bold {
			font-weight: bold;
		  }
		}
	  }
}