.see-more-link{
	display: inline-flex;
	align-items: center;
	@include rem(font-size,12px);
    @include rem('line-height', 14px);
	font-weight: bold;
	text-decoration: none;

	// Variants
    &.-small{
        @include rem(font-size,10px);
        @include rem(line-height, 12px);

        >.icon{
            &.icon-cog{
                @include rem(font-size,16px);
                @include rem(line-height, 16px);
            }
        }
    }
	&:hover{
		> .text{
			color: $c_secondary;
		}
	}

	// Children

	> .icon{
		margin-left: 10px;
		color: $c_secondary;

        &.icon-cog{
            @include rem(font-size,16px);
            @include rem(line-height, 16px);
        }
	}

	> .text{
		text-decoration: underline;
		transition: color ease .3s;
        color:$c_primary;
	}
}
