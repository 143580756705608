.login-wrapper{
    flex: 1 1 300px;
    max-width: 300px;
}

.login-header-wrapper{
    margin-bottom: 40px;

    //Children
    >.logo-wrapper{
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        text-align: center;

        //Children
        >.logo{
            width: 105px;

            //Media
            @include mq(tabletfull){
                width: 90px;
            }
        }
    }

    >.intro{
        margin: 0;
        font-family: $f_main;
        @include rem('font-size', 14px);
        @include rem('line-heifht', 16px);
        color:white;
        text-align: center;
    }
}
