.habilitation-checkbox{
	> .label{
		position: relative;
		display: block;
		width: 12px;
		height: 12px;
		margin: 0 auto;

		&.-disabled{
			opacity: .5;
			pointer-events: none;
            cursor: not-allowed;
		}

		// Children

		&::after, > .icon{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%,-50%,0);
		}

		&::after{
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			border: 1px solid #707070;
		}

		> .icon{
			display: none;
			@include rem(font-size,12px);
			margin: -2px 0 0 3px;
		}

		> .field{
			display: none;

			&:checked+.icon{
				display: block;
			}
		}
	}
}

.habilitation-date-serment{
	color: $c_secondary;
	font-weight: bold;
}
