/*****************************************\
*    MEDIAS
\*****************************************/

@mixin mq($point) {
	$mq-wide:  "(max-width: 1920px)";
	$mq-desktop:  "(max-width: 1800px)";
	$mq-desktop-only:  "(min-width: 1281px)";
	$mq-tabletfull: "(max-width: 1180px),(max-device-width: 1180px) and (orientation: landscape)";
	$mq-w1024: "(max-width: 1024px)";
	$mq-w960: "(max-width: 960px)";
	$mq-tablet: "(max-width: 880px)";
	$mq-w720: "(max-width: 720px)";
	$mq-w600: "(max-width: 600px)";
	$mq-phone: "(max-width: 480px)";
	$mq-phonemedium-p: "(max-height: 640px)";
	$mq-phonesmall: "(max-width: 320px)";
	$mq-ie10: "(-ms-high-contrast: active), (-ms-high-contrast: none)";

	$mq-phone-up : "(min-width: 769px)";

	@if $point == wide {
		@media #{$mq-wide} { @content; }
	}
	@if $point == desktop {
		@media #{$mq-desktop} { @content; }
	}
	@if $point == desktop-only {
		@media #{$mq-desktop-only} { @content; }
	}
	@else if $point == w1024 {
		@media #{$mq-w1024} { @content; }
	}
	@else if $point == w960 {
		@media #{$mq-w960} { @content; }
	}
	@else if $point == tabletfull {
		@media #{$mq-tabletfull} { @content; }
	}
	@else if $point == tablet {
		@media #{$mq-tablet} { @content; }
	}
	@else if $point == w720 {
		@media #{$mq-w720} { @content; }
	}
	@else if $point == w600 {
		@media #{$mq-w600} { @content; }
	}
	@else if $point == phone {
		@media #{$mq-phone} { @content; }
	}
	@else if $point == phonesmall {
		@media #{$mq-phonesmall} { @content; }
	}
	@else if $point == phonemedium-p {
		@media #{$mq-phonemedium-p} { @content; }
	}
	@else if $point == ie10 {
		@media #{$mq-ie10} { @content; }
	}
	@else if $point == phone-up {
		@media #{$mq-phone-up} { @content; }
	}
	@else{
		@media (max-width: #{$point}){ @content; }
	}
}

@mixin mfmq($point) {
    $mq-phone: "(min-width: 321px)";
    $mq-w600: "(min-width: 481px)";
    $mq-tablet: "(min-width: 601px)";
    $mq-tabletfull: "(min-width: 1024px)";
    $mq-small-desktop:  "(min-width: 1440px)";
    $mq-desktop:  "(min-width: 1600px)";

    @if $point == desktop {
        @media #{$mq-desktop} { @content; }
    }
    @if $point == small-desktop {
        @media #{$mq-small-desktop} { @content; }
    }
    @else if $point == tabletfull {
        @media #{$mq-tabletfull} { @content; }
    }
    @else if $point == tablet {
        @media #{$mq-tablet} { @content; }
    }
    @else if $point == w600 {
        @media #{$mq-w600} { @content; }
    }
    @else if $point == phone {
        @media #{$mq-phone} { @content; }
    }
    @else{
        @media (min-width: #{$point}){ @content; }
    }
}

/*****************************************\
*    PX TO REM
\*****************************************/

@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
	$px : ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / 10 + rem));
			}

			@if $unit == "rem" {
				$px : append($px,  ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		#{$property}: $px;
	} @else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}

/*********************************\
    WRAPPER
\*********************************/

@mixin wrapper-block(){
	margin: 0 auto;
	max-width: $c_wrapper_width;
	padding: 0 $c_wrapper_inner_margin;
}
