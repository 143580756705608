.form-wrapper {
  width: 100%;

  //Variant
  &.-repeater {
    margin-bottom: 40px;
    padding-left: 20px;

    border-left: 1px solid $c_primary;
  }

  &.-margin-top {
    margin-top: 40px;
  }

  &.-mb-20 {
    margin-bottom: 20px;
  }


  //Children
  > .mention {
    &.-bigger {
      font-weight: bold;
      margin: 0;

      @include rem("font-size", 16px);
      @include rem("line-height", 18px);
      margin-bottom: 20px;
    }

    &.-warning {
      color: $c_error;
    }
  }

  > .columns {
    display: flex;
    align-items: flex-start;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;

    //Media
    @include mq(w600) {
      flex-flow: row wrap;
    }

    //Children
    > .column {
      flex: 1 1 33%;
      max-width: calc(33% - 60px);

      margin-right: 60px;

      //Media
      @include mfmq(1681px) {
        flex: 1 1 24%;
        max-width: calc(24% - 60px);
      }

      @include mq(w600) {
        flex: 1 1 100%;
        max-width: 100%;

        margin-top: 10px;
        margin-right: 0;

        //Variant
        &:first-child {
          margin-top: 0;
        }
      }

      //Variant
      &[data-count-child-select-value="1"] {
        & + .column,
        & + .column + .column {
          padding-top: 25px;
        }
      }

      &[data-count-child-select-value="2"] {
        & + .column,
        & + .column + .column {
          padding-top: 50px;
        }

      }

      &[data-count-child-select-value="3"] {
        & + .column,
        & + .column + .column {
          padding-top: 75px;
        }

      }

      &[data-count-child-select-value="4"] {
        & + .column,
        & + .column + .column {
          padding-top: 100px;
        }

      }

      &[data-count-child-select-value="5"] {
        & + .column,
        & + .column + .column {
          padding-top: 125px;
        }
      }

      &[data-count-child-select-value="6"] {
        & + .column,
        & + .column + .column {
          padding-top: 150px;
        }
      }

      &.-full {
        flex: 1 1 100%;
        max-width: 100%;
        margin-right: 0;
      }

      &.-size-2, &.-size-2-w-margin {
        flex: 1 1 50%;
        max-width: 50%;
        margin-right: 0;
      }

      &.-pos-sticky {
        position: sticky;
        top: 10px;
      }

      &.-size-2-w-margin {
        margin-right: 60px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.form-loading {
  width: 180px;
  margin-top: 40px;

  &.-export {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.form-field-title {
  font-family: $f_second;
  font-weight: bold;

  flex: 1 1 100%;
  max-width: 100%;

  margin: 0;
  @include rem("font-size", 18px);
  @include rem("line-height", 20px);
  margin-bottom: 10px;
  text-transform: uppercase;
  color: $c_secondary;
}

.form-field-repeater {
  p {
    margin-top: 0;
  }
}

.form-link {
  font-family: $f_main;
  font-weight: 500;

  display: block;
  @include rem("font-size", 12px);
  @include rem("line-height", 16px);
  margin: 0;
  transition: color .3s ease;

  color: $c_primary;

  //Variant
  &:hover {
    color: $c_secondary;
  }

  &.-white {
    color: white;

    //Variant
    &:hover {
      color: $c_secondary;
    }
  }

  &.-right {
    text-align: right;
  }
}

.form-actions {
  display: flex;

  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 40px;

  &.-mt-0 {
    margin-top: 0;
  }

  &.-mt-20 {
    margin-top: 20px;
  }

  //Variant
  &.-outside-center {
    position: fixed;

    z-index: 2;
    bottom: 40px;
    left: 50%;
    margin: auto 0;
    transform: translateY(50%) translateX(-50%);

    //Media
    @include mq(tabletfull) {
      position: static;

      margin-top: 40px;
      transform: none;
      text-align: center;
    }
  }

  &.-outside-right {
    position: fixed;

    z-index: 2;
    right: 80px;
    bottom: 15px;
    margin: auto 0;

    //Media
    @include mq(tabletfull) {
      position: static;

      margin-top: 40px;
      transform: none;
      text-align: center;
    }
  }

  &.-right {
    justify-content: flex-end;
  }

  &.-alone {
    margin: 20px 40px 0;
  }

  &.-stats-table {
    margin: 20px 0;
  }

  &.-before {
    margin-top: 0;
    margin-bottom: 40px;
  }

  &.-sticky {
    position: sticky;
    z-index: 2;
    //z-index: 999;
    bottom: 10px;
    //justify-content: flex-end;
  }
}

.form-messages {
  font-family: $f_main;
  font-weight: bold;

  margin: 0;
  @include rem("font-size", 14px);
  @include rem("line-height", 18px);
  margin-bottom: 20px;
  text-align: left;

  color: $c_error;

  //children
  > .message {
    margin: 0;
    margin-bottom: 5px;

    //Variant
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-field-mention {
  font-family: $f_main;
  font-weight: 500;
  @include rem("font-size", 11px);
  @include rem("line-height", 13px);
  font-style: italic;
  width: 100%;
  margin: 0;
  padding: 10px 15px;
  color: $c_text_dark;
  background-color: #fafafa;

  //Variant
  &.-no-background {
    padding: 10px 0;
    background-color: white;
  }
}

.form-checkbox-group {
  margin-bottom: 20px;

  //Variant
  &:last-child {
    margin-bottom: 0;
  }

  // Children

  > .title {
    font-family: $f_second;
    display: flex;
    align-items: center;

    margin: 0 0 7px;
    @include rem(font-size, 20px);
    text-transform: uppercase;

    // Children

    > .icon {
      margin-right: 10px;
      color: $c_secondary;
      @include rem(font-size, 28px);
    }
  }
}

// Comportement particulier pour le formulaire de campagne
.campagne-form-wrapper {
  .campagne-form-coordinate {
    margin-top: 15px;
    margin-bottom: 15px;

    //Ajouter un trait de séparation entre les coordonnées
    &:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid $c_primary;
    }
  }

  .free-abonne-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .form-wrapper {
    position: relative;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;

    input {
      border: 1px solid $c_primary;
    }

    /*
    .-validate {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
     */
  }

  .flex-container {
    display: flex;
    align-items: center;
  }
}