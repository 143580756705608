.g-title {
    margin: 0;
    margin-bottom: 20px;

    font-family: $f_main;
    @include rem('font-size', 30px);
    @include rem('line-height', 34px);
    font-weight: bold;

    //Variant
    &.-center {
        text-align: center;
    }
    &.-white{
        color:white;
    }
}
