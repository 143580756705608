.inspections-generate{
	padding: 0 40px 40px;

	// Children

	> .text{
		max-width: 550px;
		margin-bottom: 35px;
		color: $c_primary;
		@include rem(font-size, 20px);
	}
}