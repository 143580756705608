.differends-summary{
	padding: 0 40px;
	@include rem(font-size,12px);
    margin-bottom: 20px;

	// Children

	> .table{
		width: 100%;
		max-width: 830px;
	}

	th, td{
		text-align: right;
		padding-bottom: 10px;

		&.-left{
			text-align: left;
		}

		&.-label{
			font-family: $f_second;
			@include rem(font-size,16px);
			font-weight: bold;
		}
	}

	th{
		color: $c_secondary;
		font-family: $f_second;
		@include rem(font-size,20px);

		&:first-child{
			color: $c_primary;
		}
	}

	td{
		&:first-child{
			color: $c_secondary;
		}
	}
}
