.form-field{
    margin: 0;
    margin-bottom: 20px;

    display: flex;
    flex-flow: column;

    &.-no-margin-b {
        margin-bottom: 0;
    }
    &.-ms-auto {
        margin-left: auto !important;
    }
    &.-me-auto {
        margin-right: auto;
    }
    &.-me-20 {
        margin-right: 20px;
    }
    &.-date-picker{
        z-index: 3;
        position: relative;
    }
    &.-small-select{
        >.select-multiple-wrapper {
            >.select-line {
                min-width: 140px;
            }
        }
    }
    &.-presence-radios{
        max-width: 160px !important;

        >.form-field-radios-group{
            flex-flow: row wrap;
            gap: 5px;

            >.radio{
                margin-right: 0;

                > .form-field-radio{
                    >.radio-wrapper{
                        margin-right: 5px;
                    }
                }
            }

        }
    }

    //Variant
    &:not(.-wysiwyg){
        &:not(.-big) {
            &:not(.-inline) {
                &:not(.-inline) {
                    &:not(.-full) {
                        max-width: 300px;
                    }
                }
            }
        }
    }
    &.-skip-line-1{
        margin-top: 84px;
    }
    &.-skip-line-1-2{
        margin-top: 168px;
    }
    &:last-child{
        margin-bottom: 0;
    }
    &.-hidden{
        margin-bottom: 0;
        .form-field-label{
            display: none;
        }
    }
    &.-wysiwyg{
        flex-flow: row wrap;

        >.form-field-label{
            flex: 1 1 100%;
            max-width: 100%;
        }
    }
    &.-small-file .form-file-field{
        height: auto;
        background-image: none!important;
        margin-bottom: 5px;

        button{
            height: auto;
        }
    }
    &.-big{
        //Children
        >.label{
            @include rem('font-size', 20px);
            @include rem('line-height', 24px);
        }

        >.input-wrapper{
            //Children
            >.form-field-input{
                padding: 20px 25px;
                height: 62px;
                max-width: 600px;
                @include rem('font-size', 14px);
                @include rem('line-height', 18px);
            }
            >.form-field-aside {
                padding: 17px 0;
                width: 70px;
                @include rem('font-size', 24px);
                @include rem('line-height', 24px);
            }
        }
    }
    &.-in-table{
        >.input-wrapper {
            //Children
            > .form-field-input {
                padding: 5px 10px;
                max-width: 80px;
                @include rem('font-size', 12px);
                @include rem('line-height', 14px);
            }
        }
    }

    &.-searchpage{

        > .form-field-label{
            color: $c_primary;
            @include rem(font-size, 20px);
            @include rem(line-height, 24px);
        }

        .form-field-aside{
            color: #fff;
            background-color: $c_primary;

            &:hover{
                color: #fff;
                background-color: $c_secondary;
            }
        }
    }
    &.-on-primary{
        .form-field-aside{
            color: $c_primary;
            background-color: $c_secondary;

            &:hover{
                color: #fff;
                background-color: $c_primary;
            }
        }
    }
    &.-on-white{
        .form-field-input {
            background-color: $c_background_light;
        }

        .search-wrapper .form-field-input{
            background-color: #fff;
        }

        .wysiwyg-editor-class,
        .wysiwyg-toolbar-class{
            background-color: #F5F5F5;
        }
    }
    &.-on-list {
        .select-line {
            min-width: auto!important;
        }
    }
    &.-hide-label{
        >.form-field-label{
            display: none;
        }
    }
    &.-hide {
        display: none;
    }

    &.-inline{
        display: inline-block;
        margin: 0 20px 0 0;

        > .label, > div{
            display: inline-block;
        }

        > .label{
            @include rem(font-size, 11px);
            font-weight: 500;
        }

        >.select-multiple-wrapper{
            margin-left: 10px;
        }
        .form-file-field{
            display:flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            height: auto;
            width: auto;
            margin:0;
            background:none;

            //Children
            >.button-wrapper{
                >.button{
                    padding: 0;

                    //Children
                    >.content{
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;

                        //Children
                        >.icon{
                            color:$c_secondary;
                            margin-right: 10px;
                        }
                    }
                }
            }
            >.filename{
                padding:0;
                font-weight: bold;
                color:$c_text_dark;
                background:none;
            }
        }
    }

    &.-full{
        width: 100%;
        max-width: 100%;
    }

    &.-list-header{
        > .form-field-label{
            display: none;
        }

        .input{
            background-color: $c_background_light;
        }
    }

    &.-form-field-open-options-at-top {
        > .select-wrapper .options-wrapper,
        > .select-multiple-wrapper .options-wrapper {
            bottom: 100%;
            top: auto;
        }
    }

    //Children
    >.select-wrapper{
        width: 100%;
        position:relative;

        >.form-field-input{
            width: 100%;
            padding-right: 45px;

            cursor:pointer;

            //Variant
            &:focus{
                outline: none;
                border-color:$c_secondary;
            }

            &:disabled {
                background-color: transparent;
                padding-left: 0;
                cursor: auto;
            }
        }

        //Variant
        &.-error{
            //Children
            .form-field-input{
                border-color:$c_error;
            }
        }
    }

    >.select-multiple-wrapper{
        position: relative;
        z-index: 2;

        &.-opened{
            z-index: 3;
        }

        //Children
        >.select-line{
            min-width: 260px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            cursor: pointer;
            border-radius: 6px;

            &.-time {
                min-width: auto;
            }

            //Children
            >.form-field-input{
                white-space: nowrap;
                overflow: hidden;
                padding-right: 10px;
                flex:1;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                //Variant
                &:focus{
                    outline: none;
                    border-color:$c_secondary;
                }
            }
            &.-options-showed{
                border-radius: 6px 6px 0 0;
                box-shadow: 0 0 4px rgba(#000,.2);
                >.form-field-input,
                >.form-field-aside{
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
        >.options-wrapper{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 250px;
            overflow: auto;
            box-shadow: 0 3px 4px rgba(#000,.2);
            background-color: $c_background_light;
            padding: 20px;

            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            //Children
            >.search-wrapper{
                margin-bottom: 20px;
            }
            >.list{
            }
        }


        //Variant
        &.-error{
            //Children
            .select-line{
                //Children
                .form-field-input{
                    border-color:$c_error;
                }
                .aside{
                    border-color:$c_error;
                }
            }
        }
    }

    &.-sm-min-width {
        >.select-multiple-wrapper {

            //Children
            > .select-line {
                min-width: 160px;
            }
        }
    }


    >.input-wrapper{
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        //Variant
        &.-error{
            //Children
            .form-field-input{
                border-color:$c_error;
            }
            .aside{
                border-color:$c_error;
            }
            >.tox-tinymce{
                border-color:$c_error;
            }
        }
        &.-with-aside{
            //Children
            >.form-field-input{
                margin-right:0;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.-fromto{
            align-items: center;
            > .text{
                flex: 1 1 10%;
                max-width: 10%;
                text-align: center;
                color: $c_primary;
                @include rem(font-size,11px);
            }
            .form-field{
                flex: 1 1 40%;
                max-width: 40%!important;
                margin-bottom: 0;
            }
            .form-field-input{
                max-width: 100%;
            }
        }

        //Children
        >.form-field-input{
            flex:1;
        }
    }
}
.form-field-label{
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 10px;

    font-family: $f_main;
    @include rem('font-size', 12px);
    @include rem('line-height', 14px);
    font-weight: bold;
    color:$c_primary;
    cursor:pointer;

    transition: color .3s ease, font-weight .3s ease;

    //Variant
    &.-error{
        color:$c_error;
    }
    &.-hidden{
        display: none;
    }
    &.-d-block {
        display: block;
    }
    &.-got-hover{
        color: $c_text_dark;

        &.-checked,
        &:hover{
            &.-fw-medium{
                color:$c_primary;
            }
        }
    }

    &.-white{
        color:white;
    }
    &.-fw-medium{
        font-weight: 500;
    }
    &.-no-margin-b{
        margin-bottom: 0;
    }
    &.-no-cursor-pointer{
        cursor: default;
    }
    &.-size-2{
        @include rem('font-size', 11px);
        @include rem('line-height', 15px);
    }
    &.-size-bigger{
        @include rem('font-size', 14px);
        @include rem('line-height', 18px);
    }
    &.-group{
        margin: 0;

        @include rem('font-size', 12px);
        @include rem('line-height', 12px);
        font-weight: bold;
        color:$c_text_dark;
    }
}
.form-field-input{
    padding: 10px 15px;
    height: 40px;

    font-family: $f_main;
    @include rem('font-size', 11px);
    @include rem('line-height', 15px);
    font-weight: 500;
    color:$c_text_dark;
    background-color:white;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border:2px solid transparent;
    border-radius:5px;

    //Variant
    &.-textarea{
        resize: none;
        height: 200px;
    }
    &.-grey{
        background-color: $c_background_light;
    }
    &.-flex-full-w{
        flex:1;
    }
    &.-full-w{
        width: 100%;
    }
    &.-time{

    }
    &:disabled {
        cursor: not-allowed;
    }
    &:focus{
        outline: none;
        border-color:$c_secondary;
    }
    &::-ms-expand {
        display: none;
    }
}
.form-field-aside{
    padding: 8px 0 7px;
    width: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    color:#fff;
    @include rem('font-size', 18px);
    @include rem('line-height', 18px);
    background-color: $c_primary;

    border:2px solid transparent;
    border-left:none;
    border-radius:5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    transition: color .3s ease, background-color .3s ease;

    //Variant
    &.-is-absolute{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        pointer-events: none;
        z-index: 2;
    }
    &.-with-text {
        @include rem('font-size', 12px);
        background-color: $c_secondary;
        width: 110px;
        font-weight: 600;
        .icon {
            margin-right: 5px;
        }
    }
    &.-is-button{
        &:hover{
            background-color:$c_text;
            color:$c_secondary;
        }
    }
    &.icon-arrow-down-full{
        @include rem('font-size', 14px);
        @include rem('line-height', 14px);

        &.-disabled{
            background-color: #eee;
        }
    }
}
.form-field-errors{
    margin: 0;
    margin-top: 5px;

    font-family: $f_main;
    @include rem('font-size', 10px);
    @include rem('line-height', 14px);
    font-weight: 500;
    text-align: left;

    color:$c_error;

    //children
    >.message{
        margin: 0;
        margin-bottom: 5px;

        //Variant
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.form-field-radios-group{
    display: flex;
    min-height: 40px;
    &.column{
        flex-direction: column;
    }

    > .radio{
        margin-right: 20px;
    }
}

.form-field-radio{
    margin: 0;
    padding: 7px 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &.form-field-radio-color{
        &.-radio{
            >.radio-wrapper{
                > .picto{
                    top: 0px;
                    left: 0px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        >.radio-wrapper{
            >.radio + .picto{
                display: block;
                opacity: 0.3;
                cursor: pointer;
            }
            >.radio:checked + .picto{
                opacity: 1;
            }
            >.picto{
                &.-color-actif{
                    background-color: $c_actif;
                }
                &.-color-rappel{
                    background-color:$c_rappel;
                }
                &.-color-report{
                    background-color:$c_report;
                }
                &.-color-annule{
                    background-color:#FC414F;
                }
                &.-color-default{
                    background-color:$c_text;
                }
            }
        }
    }

    
    //Variant
    &.-with-border-bottom{
        border-bottom:1px solid $c_separator_light;
    }
    &:last-child{
        margin-bottom: 0;
    }
    &.-hide-radio{
        >.radio-wrapper{
            display: none;
        }
    }
    &.-disabled{
        >.form-field-label{
            color: $c_text_light;
            pointer-events: none;
        }

        &.-hide-radio{
            >.form-field-label{
                color: $c_secondary;
            }
        }
    }
    //Variant
    &.-with-circle-icon{
        > .circle-icon {
            display: block;
        }
    }

    &.-radio{
        //Variant
        &.-disabled{
            >.radio-wrapper{
                opacity: .3;
            }
        }

        //Children
        >.radio-wrapper{
            border-radius: 50%;

            > .picto{
                top: 2px;
                left: 2px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #DEB578;
            }
        }
    }

    //Children
    >.radio-wrapper{
        margin-right: 10px;
        width: 14px;
        height: 14px;

        border:1px solid $c_separator_med;
        position: relative;


        //Children
        >.radio{
            display: none;
        }
        >.radio:checked + .picto{
            display: block;
        }
        >.picto{
            display: none;
            position: absolute;
            top: 1px;
            right: -4px;

            @include rem('font-size', 11px);
            @include rem('line-height', 11px);
            color:$c_primary;
        }
    }
    >.circle-icon{
        display: none;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right:7px;

        background-color:$c_primary;

        //Variant
        &.-color-actif{
            background-color: $c_actif;
        }
        &.-color-rappel{
            background-color:$c_rappel;
        }
        &.-color-report{
            background-color:$c_report;
        }
        &.-color-annule{
            background-color:#FC414F;
        }
        &.-color-default{
            background-color:$c_text;
        }
        &.-color-actif-border-report {
            background-color: $c_actif;
            border: 2px solid $c_report;
        }
    }
}
.form-field-checkbox{
    margin: 0;
    padding: 5px 0;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;


    //Variant
    &.-with-border-bottom{
        border-bottom:1px solid $c_separator_light;
    }
    &.-error{
        >.form-field-label{
            color:$c_error;
        }
    }
    &:last-child{
        margin-bottom: 0;
    }

    //Children
    >.checkbox-wrapper{
        margin-right: 10px;
        width: 14px;
        min-width: 14px;
        height: 14px;

        border:1px solid $c_separator_med;
        position: relative;

        //Children
        >.checkbox{
            display: none;
        }
        >.checkbox:checked + .picto{
            display: block;
        }
        >.picto{
            display: none;
            position: absolute;
            top: 1px;
            right: -4px;

            @include rem('font-size', 11px);
            @include rem('line-height', 11px);
            color:$c_primary;
        }
    }
}
.form-field-group{
    margin-bottom: 20px;

    //Variant
    &:last-child{
        margin-bottom: 0;
    }

    //Children
    >.group-title{
        margin: 0;
        padding-top: 5px;
    }
}

.form-field-repeater{

}
.form-field-repeater-item{
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    //Children
    >.icon{
        margin-right: 20px;

        @include rem('font-size', 12px);
        color: $c_error;

        transition: color .3s ease;
        cursor: pointer;


        //Variant
        &:hover{
            color:$c_primary;
        }
    }

}

.form-file-field{
    width: 180px;
    height: 160px;
    margin-bottom: 50px;
    background-position: center center;
    background-size: cover;
    border-radius: 10px;
    background-color:$c_background_light;

    // Children
    >.button-wrapper{

        //Variant
        &.-no-background{
            align-items: center;
        }
        &:not(.-no-background){
            background-color:white;
            border-radius:5px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        > .button{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 15px;
            background-color: rgba(#fff,0);
            border: none;
            border-radius: 10px;
            outline: none;

            transition: background-color ease .3s;

            // Children
            > .content{
                color: $c_primary;

                > .icon{
                    display: block;
                    text-align: center;
                    @include rem(font-size,23px);
                    @include rem('line-height', 23px);
                }

                > .text{
                    @include rem(font-size,12px);
                    @include rem('line-height', 14px);
                    text-decoration: underline;
                    color:$c_text;
                }
            }
        }
    }
    >.image-preview{
        margin: 0;
        padding: 0;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;

        >.image{
            width: 100%;
            display: block;
        }
    }

    > .filename{
        margin: 0;
        margin-bottom: 10px;
        padding: 5px 10px;
        padding-right: 15px;
        position: relative;

        @include rem(font-size, 12px);
        text-align: left;
        font-weight: bold;
        color: #fff;

        background-color: $c_primary;

        //Children
        >.g-button{
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    > .input{
        display: none;
    }
}
.select-current-values{
    margin: 0;
    margin-bottom: 10px;

    //Children
    >.line{
        margin: 0;
        margin-bottom: 4px;

        display:flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        //Variant
        &:last-child{
            margin-bottom: 0;
        }

        //Children
        > .remove{
            padding: 0;
            margin-right: 7px;
            background: none;
            outline: none;
            border: none;
            color: $c_error;
            transition: transform ease .3s;

            &:hover{
                transform: rotate(90deg);
            }
        }
        >.value{
            @include rem('font-size', 12px);
            color:$c_primary;
        }
    }
}

.date-field-wrapper{
    flex: 1 1 auto;
    position: relative;

    &.-with-time{
        margin-right: 10px;
    }

    > input[type=date], input[type=time], input[type=month]{
        position: relative;
        z-index: 1;

        &::-webkit-calendar-picker-indicator{
            position: absolute;
            width: 100%;
            height: 100%;
            left: -22px;
            opacity: 0;
            z-index: 3;
        }
        &::-ms-clear,&::-webkit-clear-button  {
            display: none;
        }
    }

    > .icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;
        background-color: $c_primary;
        border-radius: 0 6px 6px 0;
        z-index: 2;
        pointer-events: none;
    }
}

.select-inspecteur-row{
    margin-bottom: 10px;
}

.select-inspecteur-field{
    @extend .form-field;
    flex-flow: row nowrap;
    align-items: center;

    // Children

    > .select-wrapper{
        max-width: none;
    }

    > .remove{
        padding: 0 15px;
        margin: 0;
        border: none;
        outline: none;
        background: none;

        color: $c_error;
    }

    > .icon{
        padding: 0 15px;
        color: $c_secondary;
    }
}

.form-field-update-button {
    .form-field-input {
        background-color: transparent;
        border-color: $c_secondary;
    }
}
