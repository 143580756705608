.document-link{
	position: relative;
	display: inline-flex;
	align-items: center;
    margin: 0;
	max-width: 300px;

    //Variant
    &:hover{
        >.icon{
            color:$c_secondary
        }
        >.text{
            color:$c_secondary;
        }
    }

	// Children
	> .icon{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;

        margin-right: 10px;

		color: $c_primary;
		@include rem(font-size, 20px);

        transition: color .3s ease;
    }

	> .text{
		padding: 5px 0;
        @include rem('font-size', 12px);
        @include rem('line-height', 14px);
        color:$c_text;
        font-weight: bold;

        transition: color .3s ease;

		>.link::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
}
