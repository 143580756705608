.statistiques-office{
	display: flex;
	flex-flow: row wrap;
	max-width: 550px;
	padding: 40px 40px 0;

	// Children

	> .item{
		flex: 1 1 50%;
		max-width: 50%;
		margin-bottom: 10px;

		font-family: $f_second;
		color: $c_primary;
		@include rem(font-size, 20px);
		font-weight: bold;

		// Children

		> .title{
			color: $c_primary;
		}

		> .value{
			color: $c_secondary;
		}
	}
}

.statistiques-actions{
	display: flex;
	padding: 0 40px 20px 0;
	justify-content: flex-end;
}

.statistiques-table-wrapper{
	padding: 0 40px 40px;
	&.-edit{
		padding-top: 40px;
		background-color: #F5F5F5;

		.th{
			background-color: #fff;
		}

		input{
			padding: 5px;
			border-radius: 6px;
			border: none;
            
            @include mq(1600px){
                width:100px;
            }
            @include mq(1400px){
                width:90px;
            }
		}
	}
}
.statistiques-graphiques{
	display: flex;
	padding: 40px;

	> .graphique{
		flex: 1 1 48%;
		max-width: 48%;

		&:first-child{
			margin-right: 4%;
		}

		> .title{
			font-family: $f_second;
			@include rem(font-size, 30px);
			text-align: center;
		}
	}
}

.statistiques-totaux{
	background-color: $c_background_light;
}
