.formation-fiche-sendings{
	margin: 20px 40px;
    
    //Variant
    &.-flex{
        display: flex;
    }
    
	// Children
	> .button{
		@extend .g-button;
	}
}
