.account-block{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    position: relative;

    // Variants

    &.-menu{
        position: relative;
        display: block;
        margin: 40px 0;
        padding: 20px 30px 20px 0;

        // Children

        &::before, &::after{
            content: '';
            width: 20px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            left: 0;
        }

        &::before{
            top: 0;
        }

        &::after{
            bottom: 0;
        }

        > .name{
            > .lower, > .upper{
                display: block;
                font-weight: bold;
            }
            > .upper{
                text-transform: uppercase;
            }
        }

        > .picto{
            position: absolute;
            right: 0;
            top: 30px;


            // Variants

            &.-active{
                &+.account-block-links{
                    position: static;
                    margin-top: 20px;
                }
            }
        }

    }

    //Children
    >.name{
        margin: 0;
        margin-right: 15px;

        font-family: $f_main;
        @include rem('font-size', 14px);
        @include rem('line-height', 16px);
        color:white;
        font-weight: 500;
    }
    >.picto{
        @extend .g-picto-link;


        &.-active{
            color:white;

            &+.account-block-links{
                display: block;
            }
        }
    }

    >.account-block-links{
        display: none;
        position: absolute;
        right: 0;
        top: calc( 100% + 5px);
        width: 200px;

        background-color:white;
        padding: 10px;
    }
}
