.modal-with-form {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    // Children

    > .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        min-width: 950px;
        height: 100vh;
        min-height: 320px;

        // Children

        > .close {
            position: absolute;
            z-index: 2;
            top: 30px;
            right: 30px;
            margin: 0;
            padding: 0;
            transition: transform ease .3s;
            color: #ffffff;
            border: none;
            @include rem(font-size, 20px);
            outline: none;

            background: none;

            // Variants

            &:hover {
                transform: rotate(90deg);
            }
        }

        > .page-wrapper {
            min-width: 950px;
            min-height: 320px;
            background-color: #ffffff;
        }
    }

    .content {
        padding: 30px 40px;
    }

    //Sur les grandes résolutions, garder le style par défaut (sinon dans la modale, les colonnes se recontrent)
    .form-wrapper>.columns>.column {

        //Media
        @include mfmq(1681px){
            max-width: calc(33% - 60px);
        }
    }

    //Correction du problème d'affichage des choix du select dans la modale
    .form-field .options-wrapper {
        position: fixed;
        top: auto;
        left: auto;
        max-width: 225px;
    }

    //Correction du problème d'affichage du datepicker dans la modale
    .form-field .react-datepicker__tab-loop {
        position: absolute;

        .react-datepicker-popper {
            position: fixed !important;
            top: auto;
            left: auto;
        }
    }
}