.page-header{
	display: flex;
	align-items: center;
	justify-content: space-between;
    padding: 30px 40px;

    //Variant
    &.-with-border-bottom{
        border-bottom: 2px solid #171F46;
    }
    &.-in-fullscreen{
	    position: relative;
	    padding: 0 0 15px;
	    margin-bottom: 30px;

        //Variant
        &.-border-full-screen{
            &::after {
                width: 100%;
            }
        }

	    &::after{
		    content: '';
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    height: 1px;
		    width: 460px;
		    background-color: $c_separator_light;
	    }
    }
	&.-subtext-under{
		> .left > .title > .text > .sub{
			display: block;
			margin-left: 0;

			> .sep{
				display: none;
			}
		}
	}

	// Children

	> .left{

		// Children

		> .title{
            margin: 0;

			display: inline-flex;
			align-items: center;

			color: $c_primary;
			@include rem(font-size, 20px);
			font-weight: bold;

			// Children

			> .icon{
				margin-right: 15px;

				color: $c_secondary;
				@include rem(font-size, 30px);
                font-weight: normal;
			}

			> .text > .sub{
				font-weight: 300;
				margin-left: 15px;

				> .sep{
					display: inline-block;
					margin-right: 10px;
				}
			}
		}
	}

	> .right{
		text-align: right;

		// Children

		> .advancedsearch{
			display: inline-block;
			@include rem(font-size, 11px);
			font-weight: 500;
			margin-bottom: 5px;

			transition: color ease .3s;

			&:hover{
				color: $c_secondary;
			}
		}

		> .logo{
			width: 100px;
			height: auto;
		}
	}

}
