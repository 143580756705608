.fiche-content{
    position: relative;
    padding: 20px 0 0;
    min-height: calc(100vh - 188px);

    // Variants
    &.-right-gutter{
        padding-right: 140px;
    }

    &.-with-map{
        padding-top: 0;

        .map-wrapper{
            margin-top: 0;
            height: calc(100vh - 185px);
        }
    }
}
.fiche-content-notice{
    @extend .g-content;

    margin: 0;

    font-family: $f_main;
    @include rem('font-size', 18px);
    @include rem('line-height', 20px);
    font-weight: 500;
    color:$c_primary;
}
