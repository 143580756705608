.g-button{
    display: inline-block;
    margin:0;

    padding: 14px 20px;

    font-family: $f_main;
    @include rem('font-size', 12px);
    @include rem('line-height', 16px);
    color:$c_text_dark;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    border:none;
    border-radius: 5px;

    transition: color .3s ease, background-color .3s ease, border-color .3s ease;

    //Variant
    &.-size-2{
        padding: 7px 10px;

        &.-with-icon{

            >.picto{
                @include rem('font-size', 16px);
                //margin-right: 7px;
                margin-right: 0px;

                //Variant
                &.icon-courrier{
                    @include rem('font-size', 11px);
                }
            }
        }
    }
    & + .g-button{
        margin-left: 20px;
    }

    &.-primary{
        background-color:$c_primary;
        color: white;

        //Variant
        &:hover{
            background-color:$c_secondary;
            color:$c_primary;

            &.-on-second{
                background-color: #000;
                color:#fff;
            }
            &.-with-icon{
                >.picto{
                    color:$c_primary;
                }
            }
        }
        &.-with-icon{
            //Children
            >.picto{
                color:$c_secondary;
            }
        }
    }
    &.-with-icon{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        >.picto{
            @include rem('font-size', 20px);
            margin-right: 10px;
            color:$c_secondary;

            transition: color .3s ease;

            //Variant
            &.icon-courrier{
                @include rem('font-size', 14px);
            }
        }
    }
    &.-secondary-on-tablet{
        @include mq(tabletfull){
            background-color:$c_secondary;
            color: $c_primary;

            //Variant
            &:hover{
                background-color:white;
                color:$c_secondary;
            }
        }
    }
    &.-white{
        background-color:white;
        color: $c_primary;

        //Variant
        &:hover{
            background-color:$c_secondary;
            color:$c_primary;
        }
    }
    &.-primary-on-tablet{
        @include mq(tabletfull){
            background-color:$c_primary;
            color: white;

            //Variant
            &:hover{
                background-color:$c_secondary;
                color:white;
            }
        }
    }


    &.-secondary{
        background-color:$c_secondary;
        color: $c_primary;

        //Variant
        &:hover{
            background-color:white;
            color:$c_primary;
        }
        &.-with-icon{
            >.picto{
                color:$c_primary;
            }
        }
    }
    &.-secondary-border-only{
        background:none;
        border:1px solid $c_secondary;
        color:white;


        @include mq(tabletfull){
            color:$c_secondary;
        }

        //Variant
        &:hover{
            border:1px solid white;

            @include mq(tabletfull){
                border-color:$c_primary;
                color:$c_primary;
            }
        }
        &.-with-icon{
            >.picto{
                color:$c_primary;
            }
        }
    }
    &.-primary-border-only{
        background:none;
        border:1px solid $c_primary;
        color:$c_primary;

        @include mq(tabletfull){
            color:$c_primary;
        }

        //Variant
        &:hover{
            border:1px solid $c_secondary;
            color:$c_secondary;

            @include mq(tabletfull){
                border-color:$c_secondary;
                color:$c_secondary;
            }
            &.-with-icon{
                >.picto{
                    color:$c_secondary;
                }
            }
        }
        &.-with-icon{
            >.picto{
                color:$c_primary;
            }
        }
    }
    &.-white-border-only{
        background:none;
        border:1px solid white;
        color:white;

        //Variant
        &:hover{
            border:1px solid $c_secondary;
        }
    }
    &.-is-link{
        padding: 0;
        background: none;
        border: none;
        outline: none;
        text-decoration: underline;

        color:$c_primary;

        //Variant
        &.-white{
            color:white;

            @include mq(tabletfull){
                color:$c_secondary;
            }
        }
        &:hover{
            background:none;
            color:$c_secondary;

            &.-on-second{
                color: $c_text;
            }
        }
    }
    &.-margin-top{
        margin-top: 40px;
    }
    &.-add-button{
        padding: 7px 10px;
        background:none;
        border:1px solid $c_primary;
        color:$c_primary;

        //Variant
        &:hover{
            background-color:$c_primary;
            color:white;
        }
        &.-mb-bottom{
            margin-bottom: 40px;
        }

        &.-big-picto{
            >.picto{
                font-size: 14px;
            }
        }

        &.-primary {
            background-color: $c_primary;
            color: white;

            &:hover {
                background-color: $c_secondary;
                border-color: $c_secondary;
                color: $c_primary;
            }
        }

        //Children
        >.picto{
            font-size: 8px;
            margin-right: 5px;
            color:$c_secondary;
        }

    }
    &.-delete-button{
        padding: 5px;
        background-color: transparent;
        color: $c_error;
        margin-left:5px;

        transition: opacity .3s ease;

        &:hover{
            opacity: .7;
        }

        //Variant
        &.-with-icon{
            >.picto{
                @include rem('font-size', 15px);
                color:$c_error;
            }
            >.text{
                color:$c_text;
            }
        }
    }
    &.-edit-button{
        padding: 5px;
        background-color: transparent;
        color: $c_primary;
        margin-left:5px;

        transition: opacity .3s ease;

        //Variant
        &:hover{
            opacity: .7;
        }
        &.-with-icon{
            >.picto{
                @include rem('font-size', 15px);
                color:$c_primary;
            }
            >.text{
                color:$c_text;
            }
        }
    }
    &.-check-button{
        padding: 5px;
        background-color: transparent;
        color: $c_actif;

        transition: opacity .3s ease;

        //Variant
        &:hover{
            opacity: .7;
        }
        &.-with-icon{

            >.picto{
                color:$c_actif;
                @include rem('font-size', 15px);
            }
            >.text{
                color:$c_text;
            }
        }
    }

    &.-disabled{
        filter: grayscale(1);
        pointer-events: none;
    }


    &.-loading{
        pointer-events: none;
        min-width: 100px;
        background-color: #eee;
        height: 44px;
    }
}
