.calendar-block{
	position: relative;
	z-index: 0;
	width: 160px;
	height: 150px;
	padding-top: 62px;
	text-align: center;

	> .icon{
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		color: $c_primary;
		@include rem(font-size, 150px);
	}

	> .dates, > .years{
		display: flex;
		justify-content: center;
		margin: 0 auto;
		max-width: 100px;
	}

	> .years{
		margin-top: 2px;
		font-family: $f_second;
		font-weight: 300;
		@include rem(font-size, 20px);
		@include rem(line-height, 20px);

		> .year{
			flex: 1 1 50%;
			max-width: 50%;
			& + .year{
				margin-left: 20px;
			}
		}
	}
}

.calendar-date{
	flex: 1 1 50%;
	max-width: 50%;
	font-family: $f_second;

	& + &{
		margin-left: 20px;

		>.day::before{
			content: "-";
		}
	}

	> .day{
		position: relative;
		color: $c_secondary;
		@include rem(font-size, 40px);
		@include rem(line-height, 40px);

		&::before{
			content: "";
			position: absolute;
			right: calc(100% + 5px);
		}
	}

	> .month{
		@include rem(font-size, 20px);
		@include rem(line-height, 20px);
	}
}