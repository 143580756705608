.switch-item{
	position: relative;
	display: inline-block;
	width: 35px;
	height: 18px;
	border: 1px solid $c-separator_dark;
	border-radius: 10px;

	// Variants
    &.-attente{
        >.circle{
            color:$c_text_light;
        }
    }
	&.-active{
		> .circle{
			left: 19px;
			color: $c_actif;
		}
	}

	// Children

	> .circle{
		position: absolute;
		top: 50%;
		left: 2px;
		color: $c_annule;
		@include rem(font-size, 13px);

		transition: left ease .3s, color ease .3s;
		transform: translateY(-50%);
	}
}
