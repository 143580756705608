.list-table {
	.button{
		@extend .g-button;
	}
}

.correspondances-wrapper {
	.mb-2 {
		margin-bottom: 10px;
	}
}

