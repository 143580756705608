.informations-wrapper{
    //Children

    &.-mb-20 {
        margin-bottom: 20px;
    }

    > .gallery{
        display: flex;
        flex-flow: row wrap;

        > .image{
            flex: 1 1 24%;
            max-width: 24%;
            margin-right: 1%;

            > .picture{
                width: 100%;
            }
        }
    }

    >.columns{
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        //variant
        &.-chiffre{
            justify-content: center;
        }
        &:last-child{
            margin-bottom: 0;
        }

        //Children
        >.title{
            margin: 0;
            margin-bottom: 10px;

            flex: 1 1 100%;
            max-width: 100%;

            font-family: $f_second;
            @include rem('font-size', 18px);
            @include rem('line-height', 20px);
            text-transform: uppercase;
            color:$c_secondary;
            font-weight: bold;

            &.-alternative {
                text-decoration: underline;
                color: $c_text_dark;
                @include rem('font-size', 20px);
                @include rem('line-height', 20px);
                font-family: $f_second;
            }
            &.-with-icon-left {
                .icon {
                    margin-right: 5px;
                }
            }
        }
        >.column{
            flex: 1 1 33%;
            max-width: calc(30% - 60px);

            margin-right:40px;

            //Media
            @include mfmq(1681px){
                flex: 1 1 22%;
                max-width: calc(22% - 40px);
            }
            @include mq(w600){
                flex: 1 1 100%;
                max-width: 100%;

                margin-right:0;
                margin-top:40px;

                //Variant
                &:first-child{
                    margin-top:0;
                }
            }

            //Variant
            &.-size-2{
                flex: 1 1 50%;
                max-width: 50%;
            }
            &.-max-150{
                max-width: 150px;
            }
            &.-auto {
                flex: 0 1 auto;
                max-width: 100%;

                //Media
                @include mfmq(1681px){
                    flex: 1 1 24%;
                    max-width: calc(24% - 60px);
                }

            }
            &.-full{
                margin-right:0;
                flex: 1 1 100%;
                max-width: 100%;
            }
            &.-chiffre{
                flex: 1 1 20%;
                max-width: calc(20% - 60px);
            }
            &.-notaire{
                margin-right:30px;
                flex: 0 1 auto;
                max-width: calc(100% - 30px);
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.informations-block{
    margin: 0;
    margin-bottom: 20px;

    @include rem('font-size', 12px);
    @include rem('line-height', 16px);

    //Variant
    &:last-child{
        margin-bottom: 0;
    }
    &.-skip-line-1-2{
        margin-top: 106px;
    }
    &.-skip-line-1{
        margin-top: 74px;
    }

    //Childre,
    >.label{
        margin: 0;
        margin-bottom: 5px;

        font-weight: bold;
        color:$c_primary;

        &.-with-square {
            position: relative;
        }

        .picto {
            @include rem('font-size', 20px);
        }

        > .square {
            position: absolute;
            top: 50%;
            left: -15px;
            display: inline-block;
            width: 10px;
            height: 10px;
            transform: translateY(-50%);
        }
    }
    >.value{
        margin: 0;
        font-weight: 500;
        color:$c_text_light;
        word-break: break-word;

        //Variant
        &.-uppercase{
            text-transform: uppercase;
        }
        &.-flex{
            display: flex;
            flex-flow: row nowrap;
        }
    }
}
.informations-document-wrapper{
    margin: 0;
    margin-bottom: 5px;

    //Variant
    &:last-child{
        margin-bottom: 0;
    }
}

.informations-chiffre{
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: flex-start;

    //Children
    >.picto{
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
        @include rem('font-size', 54px);
        color:$c_secondary;
    }

    >.label{
        margin: 0;
        margin-bottom: 5px;
        @include rem('font-size', 12px);
        @include rem('line-height', 14px);

        font-weight: bold;
        color:$c_primary;
    }
    >.sublabel{
        margin: 0;
        margin-bottom: 20px;

        @include rem('font-size', 12px);
        @include rem('line-height', 14px);
        color:$c_primary;
    }
    >.chiffre{
        margin: 0;

        @include rem('font-size', 20px);
        @include rem('line-height', 20px);
        font-weight: bold;
        color:$c_primary;
    }
}

.information-text{
    margin: 0;
    margin-top: 20px;

    @include rem('font-size', 16px);
    @include rem('line-height', 18px);
    font-weight: 600;
    font-family: $f_main;
    color:$c_primary;

    //Variant
    &.-big{
        @include rem('font-size', 20px);
        @include rem('line-height', 22px);
    }
    &.-small{
        @include rem('font-size', 14px);
        @include rem('line-height', 16px);
    }
}

.informations-notaire{
    transition:filter .3s ease, opacity .3s;
    cursor: pointer;
    border: 1px solid $c_separator_light;
    border-radius: 7px;
    max-width: 182px;
    margin-bottom: 40px;


    //Variant
    &:hover{
        filter: grayscale(1);
        opacity: .7;
    }

    //Children
    >.content{
        padding: 10px;
        max-width: 182px;
        min-height: 80px;

        border-top-left-radius: 0;
        border-top-right-radius: 0;

        //Children
        >.name{
            margin: 0;
            margin-bottom: 10px;

            @include rem('font-size', 12px);
            @include rem('line-height', 14px);
            font-weight: bold;
            color:$c_primary;

        }
        >.info{
            margin: 0;

            @include rem('font-size', 11px);
            @include rem('line-height', 13px);
            font-weight: 500;
            color:$c_text_dark;
        }
    }
}

.informations-image-wrapper{
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;

    >.informations-image {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.informations-image{
    margin:0;
    padding: 0;

    //Variant
    &.-resume{
        max-width: 182px;
        border-radius:7px;
        overflow: hidden;
    }
    &.-list-notaire{
        max-width: 182px;

        border-radius:7px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
    }
    &.-certification{
        max-width: 80px;
    }
    &.-border{
        border:1px solid $c_separator_light;
        border-radius:7px;
        overflow: hidden;
    }

    //Children
    >.image{
        display: block;
        width:100%;
    }
}

.informations-text{
    font-family: $f_main;
    @include rem('font-size', 12px);
    @include rem('line-height', 16px);
    color: $c_text;

    //Variant
    &.-margin-bottom{
        margin-bottom: 30px;
    }
    &.-bigger{
        @include rem('font-size', 14px);
        @include rem('line-height', 18px);
    }
    &.-fw-medium{
        font-weight: 500;
    }
    //Children
    >p{
        margin:0;
        margin-bottom:20px;

        //variant
        &:last-child{
            margin-bottom:0;
        }
        &.-c-primary{
            color: $c_primary;
        }
    }
}

.informations-header{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    //Variant
    &.-margin-top{
        margin-top: 30px;
    }
    &.-margin-bottom{
        margin-bottom: 30px;
    }
    &.-big{
        >.title{
            @include rem('font-size', 30px);
            @include rem('line-height', 30px);
        }
    }

    //Children
    >.icon{
        margin-right: 10px;

        @include rem('font-size', 30px);
        @include rem('line-height', 30px);
        color:$c_secondary;
        font-weight: normal;
    }
    >.title{
        font-family: $f_second;
        @include rem('font-size', 20px);
        @include rem('line-height', 20px);
        color:$c_primary;
        font-weight: bold;

        //Variant
        &.-no-margin{
            margin: 0;
        }
        &.-smaller {
            @include rem('font-size', 18px);
            @include rem('line-height', 18px);
        }
    }
    >.arrow{
        margin-left: 10px;
        @include rem('font-size', 8px);
        @include rem('line-height', 8px);
        color:$c_primary;
    }
}


.informations-steps{
    //Variant
    &.-more-than-8{
        >.steps{
            //Media
            @include mq(1500px) {
                flex-flow: row wrap;
            }

            //Children
            >.step {
                @include mq(1600px) {
                    padding: 5px;
                    height: 120px;

                    > .title {
                        @include rem('font-size', 14px);
                        @include rem('line-height', 16px);
                    }
                }
                @include mq(1500px) {
                    height: 100px;
                    margin-bottom: 20px;
                }
            }
        }
    }


    //child
    >.title{
        margin: 0;

        font-family: $f_main;
        @include rem('font-size', 20px);
        @include rem('line-height', 24px);
        font-weight: bold;
        color:$c_primary;
    }
    >.pretitle{
        margin: 0;
        font-family: $f_main;
        @include rem('font-size', 16px);
        @include rem('line-height', 20px);
        font-weight: 500;
        color:$c_primary;

        //Children
        >strong{
            color: $c_secondary;
        }
    }
    >.steps{
        margin-top: 30px;

        display:flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;

        //Children
        >.step{
            flex: 1 1 140px;
            max-width: 140px;
            height: 140px;
            border: 1px solid $c_primary;
            border-radius:7px;

            padding:10px;
            margin-right: 10px;

            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color:$c_primary;

            transition: color .3s ease, background-color .3s ease;

            //Variant
            &:hover{
                background-color:$c_primary;
                color:white;
            }
            &:last-child{
                margin-right: 0;
            }

            //Children
            >.picto{
                @include rem('font-size', 30px);
                @include rem('line-height', 30px);
                color:$c_secondary;
            }
            >.title{
                margin: 0;
                margin-top: 15px;

                font-family: $f_second;
                @include rem('font-size', 16px);
                @include rem('line-height', 18px);
                font-weight: bold;
                text-transform: uppercase;
                text-align:center
            }
        }
    }
}



.informations-inspections-historique-wrapper{
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    //Children
    >.inspecteurs-list{
        flex:1 1 auto;
        max-width: 100%;
    }
    >.edit-button{
        flex: 1 1 200px;
        max-width: 200px;

        display: flex;
        justify-content: flex-end;
    }
}
