.list-filters{
	display: flex;
	align-items: center;
	padding-left: 40px;

	// Variants
    &.-documents-list{
        padding-left: 0;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom:1px solid rgba($c_separator_light, .4);
    }
	&.-margin-top{
		margin-top: 20px;
	}

	// Children

	> .icon{
		color: $c_secondary;
		@include rem(font-size, 22px);
		@include rem(line-height, 22px);
		margin-right: 22px;
        font-weight: bold;
	}
    >.year{
        margin-right: 30px;

        @include rem(font-size, 20px);
        @include rem(line-height, 20px);
        font-weight: bold;
        color: $c_primary;
    }
}

.list-content{
	position: relative;
	padding: 20px 0 20px;

	// Variants
	&.-right-gutter{
		padding-right: 140px;
	}
    &.-cotisation-list{
        min-height: calc(100vh - 318px);
    }
    &.-with-form{
        z-index: 2;
        background-color:$c_background_light;
    }
}
.list-edit-button{
    padding-left:40px;
    text-align: right;
}
.list-edit-actions{
    padding-left:40px;
}

.list-resume{
    text-align: left;
    padding: 0 40px;
    margin-bottom: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;


    //Children
    >.column{
        flex: 1 1 10%;
        max-width: 10%;
        margin-right: 20px;

        //Variant
        &:last-child{
            margin-right: 0;
        }
        &.-full{
            flex: 1 1 100%;
            max-width: 100%;
        }
        &.-double{
            flex: 1 1 20%;
            max-width: 20%;
        }

        //Children
        >.line{
            margin: 0;
            margin-bottom: 5px;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            font-family: $f_second;
            font-weight: bold;
            @include rem('font-size', 20px);
            @include rem('line-height', 20px);

            //Variant
            &:last-child{
                margin-bottom: 0;
            }

            //Children
            >.title{
                color:$c_primary;
            }
            >.value{
                color:$c_secondary;
            }
        }
    }
}

.list-buttons-wrapper{
    padding-left: 40px;
    display: flex;
}

.list-table{
	width: calc(100% - 40px);
	text-align: left;
	margin: 20px 0 0 40px;

	&.-stats{
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
    &.-no-margin{
        margin: 0;
        width: 100%;
    }

	&.-totaux{
		margin: 0;
	}

	// Children

    >.body,
    >.headers{
        &.-with-color{
            tr{
                td, th{
                    &:nth-child(2){
                        background-color: #ece3df;
                    }
                    &:nth-child(3){
                        background-color: #fff1e6;

                    }
                    &:nth-child(4){
                        background-color: #f0efeb;

                    }
                    &:nth-child(5){
                        background-color: #d6e2e9;

                    }
                    &:nth-child(6){
                        background-color: #dbe7e4;

                    }
                    &:nth-child(7){
                        background-color: #c5dedd;

                    }
                }
            }
        }
    }
    >.body{
        &.-stats{
            tr{
                text-align: right;

                >td:first-child{
                    text-align: left;
                }
            }
        }
    }
	> .headers{
		background-color: $c_background_light;

        //Variant
        &.-stats{
            .th{
                text-align: right;

                &:first-child{
                    text-align: left;
                }
            }
        }

		// Children

		.th{
			padding: 20px 15px;
			font-family: $f_second;
			text-align: left;

			&.-center{
				text-align: center;
			}
            &.-w200px{
                width: 200px;
            }
            &.-w250px{
                width: 250px;
            }
            &.-w300px{
                width: 300px;
            }
			&.-right{
				text-align: right;
			}
            &.-pre-header{
                padding: 10px 15px 0;
                >.text{
                    font-weight: 500;
                    color:$c_primary;
                }
            }
            &.-small{
                padding: 10px 10px;

                >.text{
                    @include rem(font-size, 16px);
                }
            }

			// Children
			> .link,
            >.text{
				padding: 0;
				display: inline-flex;
				align-items: center;

				font-family: $f_second;
				@include rem(font-size, 20px);
				outline: none;
				border: none;
				background: none;
                color:$c_primary;

				// Variants

				&.-no-action{
					pointer-events: none;
				}

				&.-active{
					> .icon {
						display: inline-block;
					}
				}

				&.-desc{
					> .icon {
						transform: rotate(180deg);
					}
				}

				// Children

				> .icon{
					display: none;
					margin-left: 10px;
					@include rem(font-size, 8px);
				}
			}
		}
	}

	.tr{
		transition: background-color ease .3s;
		cursor: pointer;

        //Variant
        &.-no-hover{
            cursor: default;
        }

		&.-disabled{
			opacity: .5;
		}
		&:not(.-no-hover) {
            &:hover {
                background-color: rgba($c_primary, .05);
            }
        }
        &.-with-link-absolute{
            position: relative;

            .absolute-link{
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
            }
        }

        &.-with-link-inside-td {
            cursor: auto;

            > td a {
                text-decoration: none;
                
            }
        }

	}

	.td{
		padding: 15px;
		border-bottom: 1px solid $c_separator_light;
		@include rem(font-size, 12px);
		@include rem(line-height, 18px);

        //Variant
        &.-presence{
            max-width: 160px;
            min-width: 40px;
            width:160px;
        }
        &.-h75px{
            height: 75px;
        }
        &.-w85px{
            width: 85px;
        }
        &.-w95px{
            width: 95px;
        }
        &.-w100px{
            width: 95px;
        }
        &.-w130px{
            width: 130px;
        }
        &.-w150px{
            width: 150px;
        }
        &.-w180px{
            width: 85px;
        }
        &.-w200px{
            width: 200px;
        }
        &.-w250px{
            width: 250px;
        }
        &.-w300px{
            width: 300px;
        }
        &.-w350px{
            width: 350px;
        }
        &.-w500px{
            width: 500px;
        }
        &.-w600px{
            width: 600px;
        }
		&.-bold{
			font-weight: bold;
		}

		&.-bullet{
			width: 50px;
			text-align: center;
		}

		&.-center{
			text-align: center;
		}

		&.-right{
			text-align: right;
		}
        &.-uppercase{
            text-transform: uppercase;
        }
        &.-small{
            padding: 5px;
            @include rem(font-size, 10px);
            @include rem(line-height, 14px);

            >.g-button{
                padding: 7px 10px;
            }
        }
        &.-error{
            color:$c_error;
        }

        &.-multiline{
            .line{
                margin: 0;
                margin-bottom:5px;
                padding-bottom: 5px;
                border-bottom:1px solid $c_separator_light;
                @include rem(line-height, 14px);

                //Variant
                &.-no-border{
                    border-bottom:none;
                }
                &.-small{
                    @include rem(line-height, 12px);
                    
                    >.text {
                        @include rem(font-size, 11px);
                        @include rem(line-height, 11px);
                        >.picto{
                            @include rem(font-size, 11px);
                            @include rem(line-height, 11px);
                        }
                    }
                }
                &.-historique-flex{
                    display: flex;
                    align-items: center;
                }
                &.-historique{
                    padding: 10px 0;

                    >.text{
                        >.picto{
                            @include rem(font-size, 18px);
                            @include rem(line-height, 18px);

                            //Variant
                            &.-icon-voir{
                                @include rem(font-size, 14px);
                                @include rem(line-height, 14px);
                            }
                        }
                    }
                }


                //Variant
                &:last-child{
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }

                //Children
                >.text{
                    @include rem(font-size, 12px);
                    @include rem(line-height, 12px);

                    //Variant
                    &.-secondary{
                        color:$c_secondary;
                    }
                    &.-with-picto{
                        display: inline-flex;
                        flex-flow: row nowrap;
                        align-items: center;
                    }

                    //Children
                    >.picto{
                        color:$c_secondary;
                        @include rem(font-size, 12px);
                        @include rem(line-height, 12px);
                        margin-right: 5px;
                    }
                }
            }
        }

        //Children
        >.flex-wrapper{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        > a {
            text-decoration: none;

            > .picto {
                @include rem('font-size', 20px);
            }
        }
	}
}

.list-aside{
	position: absolute;
	right: 40px;
	top: 80px;
	text-align: right;
	z-index: 2;

	// Variants

	&.-no-top-space{
		top: 40px;
	}

	&.-on-map{
		top: 120px;
	}

	// Children

	> .button{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		width: 60px;
		height: 60px;
		margin-bottom: 20px;
		border: 1px solid $c_secondary;
		background: #fff;
		border-radius: 100%;

		transition: background-color ease .5s, border-color ease .5s;

		&:hover{
			background-color: $c_primary;
			border-color: $c_primary;

			> .icon{
				color: #fff;
			}
		}

		// Children

		> .icon{
			color: $c_primary;
			@include rem(font-size, 30px);
			transition: color ease .5s;
		}
	}
}


.date-list-header{
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    >.day{
        margin-right: 5px;

        font-family: $f_second;
        @include rem('font-size', 36px);
        @include rem('line-height', 32px);
        color:$c_secondary;
        height: 30px;
    }
    >.details{
        color: $c_primary;
        font-family: $f_second;
        @include rem('font-size', 16px);
        @include rem('line-height', 16px);

        >.year,
        >.month{
            display: block;
        }

        >.month{
            font-weight: normal;
        }
        >.year{
            font-weight: 300;
            margin-top: -1px;
        }
    }
}
