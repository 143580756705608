.advanced-search{

	// Variants

	&.-hide{
		> .overlay{
			opacity: 0;
			visibility: hidden;
		}
	}

	// Chldren

	> .overlay{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 20;
		transition: opacity ease .3s, visibility ease .3s;

		// Children

		>.close{
			position: absolute;
			top: 30px;
			right: 30px;
			padding: 0;
			margin: 0;
			background: none;
			border: none;
			outline: none;
			@include rem(font-size, 20px);
			transition: transform ease .3s;

			z-index: 2;

			// Variants

			&:hover{
				transform: rotate(90deg);
			}
		}
	}

	> .summary{
		color: $c_primary;
		margin: 20px 0 20px 40px;

		> .title{
			margin: 0 0 20px;
			text-transform: uppercase;
			font-family: $f_second;
			@include rem(font-size,20px);
		}
	}
}

.search-filter{
	display: inline-flex;
	align-items: center;
	margin-right: 30px;

	@include rem(font-size,12px);

	// Children

	> .filter{
		> .value{
			font-weight: bold;
		}
        > .value + .remove{
            display: inline-block;
            margin-right: 10px;
        }
	}

	.remove{
		padding: 0;
		margin-left: 5px;
		background: none;
		outline: none;
		border: none;
		color: $c_error;
		transition: transform ease .3s;

		&:hover{
			transform: rotate(90deg);
		}
	}
}
