.tabs-wrapper{
	display: flex;
	justify-content: flex-start;
	margin: 0;
	padding: 0 40px;
	list-style: none;
	border-bottom: 2px solid $c_primary;

	// Media
	@include mq(tabletfull){
		padding: 0 20px;
	}

	&.-xtrem{
		> .tab > .icon{
			display: none;
		}
	}

	// Children

	> .tab{
		flex: 1 1 auto;
		display: flex;
		max-width: 200px;
		align-items: center;
		justify-content: center;
		padding: 0 18px;
		height: 65px;
		margin-right: 10px;

		border: 1px solid $c_primary;
		border-bottom: 0;
		border-radius: 10px 10px 0 0;

		transition: background-color ease .3s, color ease .3s;

		cursor: pointer;

		// Media
        @include mq(1400px){
            max-width: 180px;
            @include rem(font-size, 18px);
        }
		@include mq(tabletfull){
			padding: 0 10px;
			margin-right: 5px;
		}

		// Variants

		&.-icon-small{
			> .icon{
				@include rem(font-size, 10px);
			}
		}

		&.-is-active, &:hover{
			background-color: $c_primary;

			> .text{
				color: #fff;
			}
		}

		// Children

		> .icon{
			margin-right: 14px;

			color: $c_secondary;
			@include rem(font-size, 26px);


            //Variant
            &.icon-menu{
                @include rem(font-size, 10px);
            }
            &.icon-cnaf{
                @include rem(font-size, 15px);
            }

			// Media
			@include mq(tabletfull){
				margin-right: 5px;

				// Media

				@include mq(tabletfull){
					@include rem(font-size, 20px);
				}
			}
		}

		> .text{
			margin-top: 3px; // Compensation font
			font-family: $f_second;
			color: $c_primary;
			@include rem(font-size, 20px);
			@include rem(line-height, 22px);
			font-weight: bold;

			// Media
			@include mq(1400px){
				@include rem(font-size, 18px);
                @include rem(line-height, 20px);
			}
			@include mq(tabletfull){
				@include rem(font-size, 16px);
                @include rem(line-height, 18px);
			}
		}
	}
}

.tab-content{
	display: none;

	&.-active{
		display: block;
	}
}
