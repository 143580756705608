.home-wrapper {
    height: 100vh;
}

.home-header {
    flex: 0;
    height: 200px;
    padding:40px;

    background-color:$c_primary;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    >.search-wrapper{
        width: 660px;
    }
    >.toolbar-wrapper{
        margin-right: 0;
        margin-left: auto;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;

        //Children
        >.separator{
            margin-left: 15px;
            margin-right: 15px;
            width: 1px;
            height: 11px;

            background-color:white;
        }
        >.external{
            //Variant
            &:hover{
                color:white;
            }
        }
    }
}

.home-content{
    position: relative;
    height: calc(100vh - 200px);
}

.home-details-wrapper{
    position: relative;
    min-height: calc(100vh - 200px);
    padding: 20px 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;

    >.left{
        margin: 0;
        margin-right: 20px;

        flex: 1 1 60%;
        max-width: calc(60% - 20px);
    }
    >.right{
        margin: 0;
        margin-left: 20px;

        flex: 1 1 40%;
        max-width: calc(40% - 20px);
    }
}
.home-compositions-details{
    @include rem('font-size', 12px);
    @include rem('line-height', 14px);

    >.fonctions{
        margin: 0;
        margin-bottom: 5px;

        font-weight: bold;
        color: $c_primary;
    }
    >.personnes{
        margin-bottom: 15px;

        //Variant
        &:last-child{
            margin-bottom: 0;
        }

        >.personne{
            margin: 0;
            margin-bottom: 5px;
            text-decoration: none;

            //Variant
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
