.pagination-wrapper{
	display: flex;
	justify-content: flex-end;
	padding: 30px 0;
	// Children

	> .link{
		width: 40px;
		height: 40px;
		margin-right: 5px;

		color: $c_primary;
		@include rem(font-size, 14px);
		font-weight: bold;

		border-radius: 6px;
		background: none;
		border: none;

		transition: color ease .3s, background-color ease .3s;

		// Variants

		&.-active{
			color: #fff;
			background-color: $c_primary;
		}

		&:hover{
			color: #fff;
			background-color: $c_secondary;
		}
	}
}